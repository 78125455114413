import { ReactNode } from 'react';

import { Dropdown } from 'components/Dropdown/Dropdown';
import { DropdownHeaderTrigger } from 'components/Dropdown/HeaderTrigger/DropdownHeaderTrigger';
import { Icon } from 'components/Icon/Icon';
import { PageHeader } from 'components/PageHeader/PageHeader';
import { PageHeaderSearchInputsDesktop } from 'components/PageHeader/Search/Desktop/PageHeaderSearchInputsDesktop';
import { PageHeaderMobileSearchMenu } from 'components/PageHeader/Search/Mobile/PageHeaderMobileSearchMenu';
import { CartDropdownButtonContainerLoadable } from 'containers/CartDropdownButton/CartDropdownButtonContainerLoadable';
import { FlashMessageContainer } from 'containers/FlashMessageContainer';
import { MyAccountDropdownContainerLoadable } from 'containers/MyAccountDropdown/MyAccountDropdownContainerLoadable';
import { NotificationsDropdownContainerLoadable } from 'containers/NotificationsDropdown/NotificationsDropdownContainerLoadable';
import { useLogin } from 'hooks/useLogin';
import { SearchType } from 'modules/search/types/SearchType';
import { getDonateRoutePath } from 'routing/utils/getDonateRoutePath';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { showModal } from 'zustand-stores/modalStore';
import { useUserEnvironment } from 'zustand-stores/userEnvironmentStore';

import { pageHeaderContainerDonateTextByLocale } from './pageHeaderContainerDonateTextByLocale';
import { usePageHeaderContainerSearch } from './usePageHeaderContainerSearch';

type Props = {
  searchFilters?: ReactNode;
  showDesktopSearch: boolean;
  defaultSearchType: SearchType;
  progressBar: ReactNode;
  isInSearchPage: boolean;
  isInSearchLandingPage: boolean;
};

export function PageHeaderContainer({
  searchFilters,
  showDesktopSearch,
  defaultSearchType,
  progressBar,
  isInSearchPage,
  isInSearchLandingPage,
}: Props) {
  const {
    initialQuery,
    searchLocation,
    changeSearchLocation,
    search,
    searchOnClear,
  } = usePageHeaderContainerSearch({
    defaultSearchType,
    isInSearchPage,
    isInSearchLandingPage,
  });

  const userEnvironment = useUserEnvironment();
  const { user, isUserLoaded } = userEnvironment;

  const { loginLink, onClickLogin } = useLogin();

  return (
    <PageHeader
      locale={CURRENT_LOCALE}
      loginHref={loginLink}
      signupHref="/signup"
      isUserLoaded={isUserLoaded}
      onClickLogin={onClickLogin}
      onClickSignup={(e) => {
        e.preventDefault();
        showModal('SIGNUP', {});
      }}
      donate={{
        href: getDonateRoutePath(CURRENT_LOCALE),
        text: pageHeaderContainerDonateTextByLocale[CURRENT_LOCALE],
      }}
      userDisplayName={user?.name || user?.email}
      orgs={user?.orgs || []}
      notificationsDropdown={<NotificationsDropdownContainerLoadable />}
      myAccountDropdown={<MyAccountDropdownContainerLoadable />}
      cartDropdownButton={<CartDropdownButtonContainerLoadable />}
      desktopSearch={
        showDesktopSearch && (
          <PageHeaderSearchInputsDesktop
            defaultSearchType={defaultSearchType}
            initialQuery={initialQuery}
            searchLocation={searchLocation}
            onChangeSearchLocation={changeSearchLocation}
            onSearch={search}
            searchOnClear={searchOnClear}
          />
        )
      }
      mobileSearchDropdown={
        <Dropdown
          trigger={
            <DropdownHeaderTrigger
              ariaLabel={getText('Toggle search')}
              qaId="search-menu-button"
              variant="grey"
            >
              <Icon name="search" size={20} />
            </DropdownHeaderTrigger>
          }
          onToggle={(open) =>
            open && trackClicked('Header Utility CTA - Search')
          }
        >
          <PageHeaderMobileSearchMenu
            // Reset menu when initial listing type changes so that the popular search work properly
            key={defaultSearchType}
            defaultSearchType={defaultSearchType}
            initialQuery={initialQuery}
            searchLocation={searchLocation}
            onChangeSearchLocation={changeSearchLocation}
            onSearch={search}
            searchOnClear={searchOnClear}
          />
        </Dropdown>
      }
      below={
        <>
          {searchFilters}
          <FlashMessageContainer />
          {progressBar}
        </>
      }
    />
  );
}
