import { Outlet } from 'react-router';
import { ThemeProvider } from 'styled-components';

import { ActionToastProvider } from 'components/ActionToast/ActionToast';
import { ErrorBoundary } from 'containers/ErrorBoundary';
import { SetFlashMessages } from 'containers/SetFlashMessages';
import { SetGMapsError } from 'containers/SetGMapsError';
import { TabContextProvider } from 'containers/TabContext';
import { UpdateRequiredContextProvider } from 'containers/UpdateRequiredContext';
import { ThemeGlobalStyle } from 'theme/ThemeGlobalStyle';
import { themeContextValue } from 'theme/theme';

import { AppHooks } from './AppHooks';
import { ModalRoot } from './containers/Modal/ModalRoot';

export function App() {
  return (
    <ThemeProvider theme={themeContextValue}>
      <ThemeGlobalStyle />
      <AppHooks />

      <ErrorBoundary>
        <SetGMapsError />
        <SetFlashMessages />
        <UpdateRequiredContextProvider>
          <TabContextProvider>
            <ModalRoot />
            <ActionToastProvider />
            <Outlet />
          </TabContextProvider>
        </UpdateRequiredContextProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
