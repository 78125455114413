import { css } from 'styled-components';

import { range } from 'utils/functional/array/range';

export enum ViewportBreakpoint {
  'xs' = 'xs',
  'sm' = 'sm',
  'md' = 'md',
  'lg' = 'lg',
}

export const breakpointValues = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1280,
} satisfies Record<ViewportBreakpoint, number>;

export const cssBreakpoints = {
  // Up
  xsUp: `${breakpointValues.xs}px`,
  smUp: `${breakpointValues.sm}px`,
  mdUp: `${breakpointValues.md}px`,
  lgUp: `${breakpointValues.lg}px`,

  // Down
  xsDown: `${breakpointValues.xs - 1}px`,
  smDown: `${breakpointValues.sm - 1}px`,
  mdDown: `${breakpointValues.md - 1}px`,
  lgDown: `${breakpointValues.lg - 1}px`,
} as const;

// @deprecated
// When using spacing values, please use `spacing`
// If there are legacy components that do fit the
// new spacing values, please consult with design
export const legacySpace = [0, 4, 8, 16, 32, 64, 128];

// Correspond to values defined at
// https://www.figma.com/design/lEBGkrLMQN7Ujkwvq2aiIb/Idealist-Design-System?node-id=2449-2797&t=96OZuN8nN1lSgpik-0
// `spacing` should be used for things like body content, lists, forms, and other values that are defined
// in mockups - if in doubt, ask design!
export const spacing = {
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  16: '16px',
  24: '24px',
  30: '30px',
  40: '40px',
  60: '60px',
  70: '70px',
  100: '100px',
  140: '140px',
  200: '200px',
} as const;

export type Spacing = (typeof spacing)[keyof typeof spacing];

export const dashboardSidebarWidth = 496;
export const dashboardMainWidth = breakpointValues.lg;
export const maxWidth = [null, null, 1000, 1248, 1600] as const;
export const gutterWidth = 48;
export const sectionPadding = ['40px 0 48px', null, '60px 0 72px', null];
export const mediaRetina =
  '@media all and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)';
export const shadows = {
  toolbar: '0 4px 9px rgba(0,0,0,0.1), 0 1px rgba(0,0,0,0.0375)',
  highest: '0 3px 13px rgba(0,0,0,0.125), 0 0 0 1px rgba(0,0,0,0.0375)',
  high: '0 1px 10px rgba(0,0,0,0.15), 0 0 0 1px rgba(0,0,0,0.025)',
  low: '0 1px 4px rgba(0, 0, 0, 0.175)',
};
export const fontSizes = {
  12: '12px',
  14: '14px',
  16: '16px',
  20: '20px',
  24: '24px',
  32: '32px',
  48: '48px',
  64: '64px',
  72: '72px',
  96: '96px',
} as const;
export const fontSizesByType = {
  h1: [30, null, 40, 48],
  h2: [26, null, 32, 36],
  h3: [22, null, 24],
  h4: [18, null, 20],
  h5: [14, null, 16],
  h6: [12, null, 14],
  headingSmall: [12],
  bodyLarge: [24, null, 30],
  body: [18, null, 20],
  bodySmall: [14, null, 16],
  bodyMicro: [12, null, 14],
};

export const lineHeightByType = {
  h1: 1.125,
  h2: 1.25,
  h3: 1.458333333333333,
  h4: 1.75,
  h5: 1.875,
  h6: 1.875,
  headingSmall: 1.5,
  bodyLarge: 1.5,
  body: 1.75,
  bodySmall: 1.875,
  bodyMicro: 1.875,
};
export const fontWeights = {
  normal: 400,
  semiBold: 600,
  bold: 700,
};

export const colors = {
  black: 'var(--color-black)',
  white: 'var(--color-white)',
  // primary:
  brandBlue: 'var(--color-brandBlue)',
  brandGreen: 'var(--color-brandGreen)',
  brandYellow: 'var(--color-brandYellow)',
  skyBlue: 'var(--color-skyBlue)',
  secondarySkyBlue: 'var(--color-secondarySkyBlue)',
  softBlue: 'var(--color-softBlue)',
  backgroundBlue: 'var(--color-backgroundBlue)',
  dustyBlue: 'var(--color-dustyBlue)',

  // secondary:
  lightYellow: 'var(--color-lightYellow)',
  lightRed: 'var(--color-lightRed)',
  lightSage: 'var(--color-lightSage)',
  lightPurple: 'var(--color-lightPurple)',
  selectionBlue: 'var(--color-selectionBlue)',

  // grey matter:
  backgroundGrey: 'var(--color-backgroundGrey)',
  inputGrey: 'var(--color-inputGrey)',
  calloutGrey: 'var(--color-calloutGrey)',
  selectionGrey: 'var(--color-selectionGrey)',
  elementGrey: 'var(--color-elementGrey)',
  lightContentGrey: 'var(--color-lightContentGrey)',
  mediumContentGrey: 'var(--color-mediumContentGrey)',
  darkContentGrey: 'var(--color-darkContentGrey)',

  // alerts and actions:
  accessibleYellow: 'var(--color-accessibleYellow)',
  hoverBlue: 'var(--color-hoverBlue)',
  decorativePlum: 'var(--color-decorativePlum)',
  decorativeStraw: 'var(--color-decorativeStraw)',
  brightPlum: 'var(--color-brightPlum)',
  alertRed: 'var(--color-alertRed)',
  alertRedHover: 'var(--color-alertRedHover)',
  natureGreen: 'var(--color-natureGreen)',
  tagLemonGreen: 'var(--color-tagLemonGreen)',
  lemon: 'var(--color-lemon)',
  decorativePoppy: 'var(--color-decorativePoppy)',
};

export type Color = keyof typeof colors;

export const transition = 'var(--transition)';
export const fonts = {
  // Fallback generated with https://screenspan.net/fallback
  sans: "'Source Sans Pro', 'Source Sans Pro-fallback', sans-serif",
};
const textBase = `
  &:first-child {
    margin-top: 0;
  }
  > *:first-child {
    margin-top: 0;
  }
`;
export const bodyTextBase = `
  ${textBase}
  font-weight: ${fontWeights.normal};
`;
const headlineTextBase = `
  ${textBase}
  font-weight: ${fontWeights.semiBold};
`;
export const h1Text = `
  ${headlineTextBase};
  font-size: ${fontSizesByType.h1[0]}px;
  line-height: 1.125;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.h1[2]}px;
  }
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    font-size: ${fontSizesByType.h1[3]}px;
  }
`;
export const h2Text = `
  ${headlineTextBase};
  font-size: ${fontSizesByType.h2[0]}px;
  line-height: 1.25;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.h2[2]}px;
  }
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    font-size: ${fontSizesByType.h2[3]}px;
  }
`;
export const h3Text = `
  ${headlineTextBase};
  font-size: ${fontSizesByType.h3[0]}px;
  line-height: 1.458333333333333;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.h3[2]}px;
  }
`;
export const h4Text = `
  ${headlineTextBase};
  font-size: ${fontSizesByType.h4[0]}px;
  line-height: 1.75;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.h4[2]}px;
  }
`;
export const h5Text = `
  ${headlineTextBase};
  font-size: ${fontSizesByType.h5[0]}px;
  line-height: 1.875;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.h5[2]}px;
  }
`;
export const h6Text = `
  ${headlineTextBase};
  font-size: ${fontSizesByType.h6[0]}px;
  line-height: 1.875;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.h6[2]}px;
  }
`;
export const bodyLargeText = `
  ${bodyTextBase};
  font-size: ${fontSizesByType.bodyLarge[0]}px;
  line-height: 1.5;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.bodyLarge[2]}px;
  }
`;
export const bodyText = `
  ${bodyTextBase};
  font-size: ${fontSizesByType.body[0]}px;
  line-height: 1.75;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.body[2]}px;
  }
`;
export const bodySmallText = `
  ${bodyTextBase};
  font-size: ${fontSizesByType.bodySmall[0]}px;
  line-height: 1.875;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.bodySmall[2]}px;
  }
  a {
    font-weight: ${fontWeights.semiBold};
  }
`;
export const bodyMicroText = `
  ${bodyTextBase};
  font-size: ${fontSizesByType.bodyMicro[0]}px;
  line-height: 1.875;
  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.bodyMicro[2]}px;
  }
`;
export const headingSmallText = `
  ${bodyTextBase};
  font-size: ${fontSizesByType.headingSmall[0]}px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  line-height: 1.5;
`;
export const underlineText = `
  background-image: linear-gradient(
    to bottom,
    ${colors.brandYellow} 0%,
    ${colors.brandYellow} 100%
  );
  background-position: 0 40px;
  background-repeat: repeat-x;
  background-size: 100% 5px;
  text-decoration: none;
  font-weight: ${fontWeights.semiBold};
  font-style: normal;
`;

export const linkStyles = css`
  color: ${colors.brandBlue};
  font-weight: ${fontWeights.semiBold};
  word-wrap: break-word;

  &,
  &:active,
  &:visited {
    text-decoration: none;
  }

  &:hover {
    color: ${colors.hoverBlue};
    text-decoration: underline;
  }
`;

export const textStyles = {
  h1: h1Text,
  h2: h2Text,
  h3: h3Text,
  h4: h4Text,
  h5: h5Text,
  h6: h6Text,
  headingSmall: headingSmallText,
  bodyLarge: bodyLargeText,
  body: bodyText,
  bodySmall: bodySmallText,
  bodyMicro: bodyMicroText,
  underline: underlineText,
};
export const richText = css`
  ${textStyles.body};

  * {
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
  }

  ${[1, 2, 3, 4, 5]
    .map(
      (num) => `
    h${num} {
      ${
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        textStyles[`h${num}`]
      }
    }
  `,
    )
    .join(' ')}

  h1,
  h2,
  h3 {
    margin-top: 40px;

    + * {
      margin-top: 32px;
    }

    strong,
    u,
    b,
    em {
      /* block-no-empty */
      ${underlineText};
    }
  }

  h4,
  h5,
  p,
  ul,
  ol {
    margin: 0.75em 0;

    + p,
    + ul,
    + ol,
    + h4,
    + h5 {
      margin-top: 0.75em;
    }
  }

  p,
  ul,
  ol {
    ${textStyles.body};
    font-size: inherit;

    @media all and (min-width: ${cssBreakpoints.smUp}) {
      font-size: inherit;
    }
  }

  ul,
  ol {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;

    li {
      position: relative;
      padding-left: ${gutterWidth}px;

      &:not(.ql-indent-1, .ql-indent-2, .ql-indent-3) {
        counter-reset: list-1 list-2 list-3;
      }

      &.ql-indent-1 {
        counter-reset: list-2 list-3;
      }

      &.ql-indent-2 {
        counter-reset: list-3;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        color: ${colors.brandBlue};
        display: inline-block;
        width: ${(gutterWidth * 3) / 4}px;
        text-align: right;
      }
      ${range(1, 4).map(
        (i) => `
      &.ql-indent-${i} {
        padding-left: ${gutterWidth * (i + 1)}px;
        &::before {
          left: ${gutterWidth * i}px;
        }
      }`,
      )}
    }
  }

  ul {
    li {
      &::before {
        content: '•';
      }
    }
  }

  ol {
    counter-reset: ordered-list;

    li {
      counter-increment: ordered-list;

      &::before {
        content: counter(ordered-list) '.';
      }

      ${range(1, 4).map(
        (i) => `
      &.ql-indent-${i} {
        counter-increment: list-${i};

        &::before {
          content: counter(list-${i}) '.';
        }
      }`,
      )}
    }
  }

  a {
    word-break: break-word;

    &,
    &:active,
    &:visited {
      text-decoration: none;
      color: ${colors.brandBlue};
    }

    &:hover {
      color: ${colors.hoverBlue};
      text-decoration: underline;
      text-decoration-color: ${colors.hoverBlue};
    }
  }

  strong {
    font-weight: ${fontWeights.semiBold};
  }

  blockquote {
    position: relative;
    width: calc(100% + ${gutterWidth}px);
    margin: 32px -${gutterWidth / 2}px;
    padding: 32px ${gutterWidth / 2}px;
    ${textStyles.bodyLarge};
    text-align: center;
    border-radius: 10px;
    background: ${colors.backgroundGrey};

    &::before {
      content: '\\201c';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -35%);
      color: ${colors.brandYellow};
      font-size: 100px;
      font-weight: 700;
      line-height: 1;
    }

    &[cite]::after {
      content: attr(cite);
      margin-top: 24px;
      display: block;
      ${textStyles.bodySmall};
    }

    @media all and (min-width: ${cssBreakpoints.smUp}) {
      width: calc(100% + ${gutterWidth * 2}px);
      margin: 64px -${gutterWidth}px;
      padding: 60px ${gutterWidth}px;
    }
  }

  &:first-child {
    margin-top: 0;

    > *:first-child {
      margin-top: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;

    > *:nth-child(n + 2):last-child {
      margin-bottom: 0;
    }
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
`;

export const richTextEditor = css`
  ${richText};

  font-size: ${fontSizesByType.bodySmall[0]}px;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    font-size: ${fontSizesByType.bodySmall[2]}px;
  }

  ${[1, 2, 3, 4, 5]
    .map(
      (num) => css`
        h${num} {
          font-size: ${
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            fontSizesByType[`h${num}`][0] * 0.8
          }px;

          @media all and (min-width: ${cssBreakpoints.smUp}) {
            font-size: ${
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              fontSizesByType[`h${num}`][0] * 0.8
            }px;
          }
        }
      `,
    )
    .join(' ')}
`;

const themeFontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96];
export const themeContextValue = {
  breakpoints: [
    cssBreakpoints.xsUp,
    cssBreakpoints.smUp,
    cssBreakpoints.mdUp,
    cssBreakpoints.lgUp,
  ],
  space: legacySpace,
  fontSizes: themeFontSizes,
  fontWeights,
  fonts,
  colors,
  transition, // shadows
};

type StyleObject = {
  name: UtilityClass;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styles: Record<string, any> | string;
};

export const utilityClasses: Array<StyleObject> = [
  {
    name: 'flex',
    styles: {
      display: 'flex',
    },
  },
  {
    name: 'inline-flex',
    styles: {
      display: 'inline-flex',
    },
  },
  {
    name: 'align-center',
    styles: {
      alignItems: 'center',
    },
  },
  {
    name: 'justify-between',
    styles: {
      justifyContent: 'space-between',
    },
  },
  {
    name: 'justify-center',
    styles: {
      justifyContent: 'center',
    },
  },
  {
    name: 'rich-text',
    styles: richText,
  },
];

export function getClassName(
  className?: string,
  classes?: Array<UtilityClass>,
): string {
  return `${
    classes
      ? `${classes.map((c) => `idlst-${c.replace(/[aeiou-]/g, '')}`).join(' ')}`
      : ''
  } ${className || ''}`;
}

export const globalStyles = `
  body {
    margin: 0;
    box-sizing: border-box;
  }

  body .pac-container {
    z-index: 100001;
  }

  button {
    padding: 0;
    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
  }

  a {
    color: #0d73d9;
  }

  a:hover {
    color: #0042c1;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }
`;
