import { Button } from 'components/Button/Button';
import { H5 } from 'components/Text/H5';

import { SearchFiltersHeaderHolder } from './SearchFiltersHeader.styled';

type Props = {
  areFiltersActive?: boolean;
  onClickClearAll?: () => void;
};

export function SearchFiltersHeader({
  areFiltersActive,
  onClickClearAll,
}: Props) {
  return (
    <SearchFiltersHeaderHolder>
      {areFiltersActive ? (
        <Button
          noPadding
          variant="link"
          type="button"
          onClick={onClickClearAll}
        >
          {getText('Clear Filters')}
        </Button>
      ) : (
        <H5 mb={0}>{getText('Filters')}</H5>
      )}
    </SearchFiltersHeaderHolder>
  );
}
