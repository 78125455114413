import { MouseEvent, useCallback } from 'react';
import { createSearchParams, useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import { trackClicked } from 'utils/analytics/track/trackClicked';

export function useSearchFacetReset(facetType: string) {
  const navigate = useDeferredNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);

  const resetFacet = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      trackClicked(`Reset Search Filter - ${facetType}`);

      searchParamsRef.current.delete(facetType);

      const newSearchParams = createSearchParams(searchParamsRef.current);

      navigate(`?${newSearchParams}`, { replace: true });

      const filterRef = e.currentTarget.closest('[data-facet-type]');

      if (filterRef !== null) {
        filterRef.querySelectorAll('input').forEach((option) => {
          if (option instanceof HTMLInputElement) {
            // eslint-disable-next-line no-param-reassign
            option.checked = Boolean(
              option.value === '' && option.type === 'radio',
            );
          }
        });
      }
    },
    [facetType, navigate, searchParamsRef],
  );

  return resetFacet;
}
