import styled from 'styled-components';

import {
  detailsStateClosedClass,
  detailsStateOpenClass,
} from 'components/Details/detailsClasses';
import {
  detailsOneWhenClosedMinusOneWhenOpenVar,
  detailsOneWhenClosedZeroWhenOpenVar,
  detailsZeroWhenClosedOneWhenOpenVar,
} from 'components/Details/detailsCssVariables';
import { cssBreakpoints, transition } from 'theme/theme';

export const AccordionDetails = styled.details`
  ${detailsOneWhenClosedZeroWhenOpenVar}: 1;
  ${detailsZeroWhenClosedOneWhenOpenVar}: 0;
  ${detailsOneWhenClosedMinusOneWhenOpenVar}: 1;

  &[open] {
    ${detailsOneWhenClosedZeroWhenOpenVar}: 0;
    ${detailsZeroWhenClosedOneWhenOpenVar}: 1;
    ${detailsOneWhenClosedMinusOneWhenOpenVar}: -1;
  }

  &.${detailsStateOpenClass} {
    > div:last-of-type {
      transition: ${transition};
      transition-property: transform;
    }
  }

  &.${detailsStateClosedClass} {
    > div:last-of-type {
      visibility: hidden;

      @media all and (max-width: ${cssBreakpoints.mdDown}) {
        transform: translateY(-10%);
      }

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        transform: translateY(-10px);
      }
    }
  }

  > summary {
    list-style-type: none;
  }
`;
