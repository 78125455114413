import { ReactNode, useEffect } from 'react';
import { useLoaderData, useRevalidator } from 'react-router';

import { LoadingSection } from 'components/LoadingSection/LoadingSection';

import { renderClientOnlyLoaderToken } from './renderClientOnlyLoaderToken';

type Props<TData> = {
  render: (data: TData) => ReactNode;
};

export function RenderLoaderComponent<TData>({ render }: Props<TData>) {
  const loaderData = useLoaderData() as TData;
  const { revalidate } = useRevalidator();

  const shouldRevalidate = loaderData === renderClientOnlyLoaderToken;

  useEffect(() => {
    if (shouldRevalidate) revalidate();
  }, [revalidate, shouldRevalidate]);

  if (shouldRevalidate) return <LoadingSection />;

  return render(loaderData);
}
