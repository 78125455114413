export const EMPLOYMENT_TYPES = {
  FULL_TIME: getText('Full-Time Employee'),
  PART_TIME: getText('Part-Time Employee'),
  TEMPORARY: getText('Temporary'),
  CONTRACT: getText('Contract/Freelance'),
} as const satisfies Record<string, string>;

export type EmploymentType = keyof typeof EMPLOYMENT_TYPES;

export const EMPLOYMENT_TYPE_CHECKBOX_OPTIONS = Object.entries(
  EMPLOYMENT_TYPES,
).map(([key, value]) => ({ name: key, label: value }));
