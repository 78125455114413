import { RouteObject } from 'react-router';

import { LayoutHandleProps } from 'layouts/Layout/LayoutHandleProps';
import { salariesApiFetchJobSynonym } from 'modules/salaries/api/salariesApiFetchJobSynonym';
import { SearchJobsPageLoadable } from 'modules/search/pages/Jobs/SearchJobsPageLoadable';
import { SearchJobsPageFilters } from 'modules/search/pages/Jobs/components/SearchJobsPageFilters';
import { AppRouteLoaderArgs } from 'routing/classes/types/AppRouteLoaderArgs';
import { renderLoader } from 'routing/utils/renderLoader/renderLoader';

import { searchGetJobsRoutePath } from './helpers/searchGetJobsRoutePath';
import { searchJobsRouteReference } from './search.routes';

const urlByLocale = {
  en: searchGetJobsRoutePath({ lang: 'en' }),
  es: searchGetJobsRoutePath({ lang: 'es' }),
  pt: searchGetJobsRoutePath({ lang: 'pt' }),
} as const satisfies Record<UserLocale, string>;

export const searchJobsRouteObjects = (['en', 'es', 'pt'] as const).map(
  (locale) => ({
    path: urlByLocale[locale],
    id: `search-jobs-${locale}`,
    handle: {
      searchFilters: <SearchJobsPageFilters />,
      urlByLocale,
      defaultSearchType: 'JOB',
      hideFooterDivider: true,
      showDesktopSearch: true,
      isSearchPage: true,
    } satisfies LayoutHandleProps,
    ...renderLoader({
      loader: async ({
        request,
      }: AppRouteLoaderArgs<typeof searchJobsRouteReference>) => {
        try {
          const url = new URL(request.url);

          const synonymId = url.searchParams.get('synonymId');

          const [synonym] = await Promise.all([
            synonymId
              ? salariesApiFetchJobSynonym(synonymId)
              : Promise.resolve(),
          ]);

          return { synonym };
        } catch (e) {
          // @TODO: Should we log and/or throw an error?
          return { synonym: null, facetCounts: null };
        }
      },
      render: () => <SearchJobsPageLoadable />,
    }),
    shouldRevalidate: ({ currentUrl, defaultShouldRevalidate, nextUrl }) => {
      if (currentUrl.searchParams.size === nextUrl.searchParams.size) {
        return false;
      }

      return defaultShouldRevalidate;
    },
  }),
) satisfies RouteObject[];
