import { useEffect } from 'react';
import { useSearchParams } from 'react-router';

import { useSearchLocation } from 'modules/search/zustand-stores/searchStore';
import { SEARCH_RADIUS_RADIO_OR_SELECT_OPTIONS } from 'utils/constants/general/searchRadiuses';

export function useSearchRadiusOptions() {
  const searchLocation = useSearchLocation();
  const [searchParams] = useSearchParams();

  const defaultOptions = SEARCH_RADIUS_RADIO_OR_SELECT_OPTIONS[CURRENT_LOCALE];

  const selectedRadiusValue = searchParams.get('radius');
  const isOnsiteSelected = searchParams.has('locationType', 'ONSITE');
  const isInUS = searchLocation?.countryCode === 'US';

  const includeState = !isOnsiteSelected && isInUS;
  const includeCountry = !isOnsiteSelected;

  const options = defaultOptions.filter(
    (option) =>
      (includeCountry || option.value !== 'country') &&
      (includeState || option.value !== 'state'),
  );

  useEffect(() => {
    if (
      (selectedRadiusValue === 'country' && !includeCountry) ||
      (selectedRadiusValue === 'state' && !includeState)
    ) {
      searchParams.delete('radius');
    }
  }, [includeCountry, includeState, searchParams, selectedRadiusValue]);

  return options;
}
