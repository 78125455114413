import { PropsWithChildren, ReactElement, useCallback, useRef } from 'react';

import { useDetailsToggleObserver } from 'components/Details/hooks/useDetailsToggleObserver';
import { useDetailsTransitionClasses } from 'components/Details/hooks/useDetailsTransitionClasses';

import { AccordionDetails } from './AccordionItem.styled';

export type Props = {
  defaultOpen?: boolean;
  header?: ReactElement<'summary'>;
  qaId?: string;
  className?: string;
  id?: string;
  name?: string;
  onToggle?: (open: boolean) => void;
};

export function AccordionItem({
  defaultOpen,
  children,
  className,
  header,
  name,
  id,
  onToggle,
  qaId,
}: PropsWithChildren<Props>) {
  const detailsElRef = useRef<HTMLDetailsElement>(null);

  const { onToggleAnimation } = useDetailsTransitionClasses({
    detailsElRef,
  });

  const toggle = useCallback(
    (open: boolean) => {
      onToggleAnimation(open);
      onToggle?.(open);
    },
    [onToggle, onToggleAnimation],
  );

  useDetailsToggleObserver({ detailsElRef, onToggle: toggle });

  return (
    <AccordionDetails
      id={id}
      name={name}
      className={className}
      ref={detailsElRef}
      open={defaultOpen}
      data-qa-id={qaId}
    >
      {header}
      {children}
    </AccordionDetails>
  );
}
