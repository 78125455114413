import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterCheckboxFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterCheckboxFacetOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/filters/useSearchFacetSelectedValues';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { LOCALE_CHECKBOX_OPTIONS } from 'utils/constants/general/locales';

type Props = {
  variant?: SearchFilterVariant;
};
export function LocaleFilter({ variant = 'dropdown' }: Props) {
  const selectedFilterValues = useSearchFacetSelectedValues('locale');

  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  return (
    <FilterVariant
      facetType="locale"
      icon="speech-bubble"
      label={
        <>
          {getText('Listing Language')}{' '}
          <TextBadge content={selectedFilterValues.length} />
        </>
      }
    >
      <SearchFilterCheckboxFacetOptions
        facetType="locale"
        initialValue={selectedFilterValues}
        options={LOCALE_CHECKBOX_OPTIONS}
      />
    </FilterVariant>
  );
}
