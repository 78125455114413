import { SearchParamsObject } from '@algolia/client-search';

import { searchAlgoliaOptionsFiltersForFacets } from 'modules/search/algolia/options/searchAlgoliaOptionsFiltersForFacets';
import { searchFacetsOrg } from 'modules/search/constants/searchFacetsOrg';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { algoliaQueryStringFilter } from 'utils/algolia/helpers/algoliaQueryStringFilter';
import { SEARCH_PAGE_SIZE } from 'utils/constants/general/searchPageSize';

const attributesToRetrieve = [
  'objectID',
  'type',
  'published',
  'name',
  'city',
  'state',
  'stateStr',
  'country',
  'url',
  'orgID',
  'orgUrl',
  'orgName',
  'orgType',
  'jobType',
  'logo',
  'locationType',
  'remoteZone',
  'paid',
  'payment',
  '_geoloc',
  'hasPublishedListings',
  'actionType',
  'source',
  'timezone',
  'hasAts',
  'isPostedAnonymously',
  'hiring',
  'seekingVolunteers',
  'hasUpcomingEvents',
  'areasOfFocus',
  'salaryMinimum',
  'salaryMaximum',
  'salaryCurrency',
  'salaryPeriod',
];

type Args = {
  filtersById: SearchFiltersById;
  searchLocation: SearchLocation | undefined;
};

export function searchAlgoliaOrgsOptions({
  filtersById,
  searchLocation,
}: Args): SearchParamsObject {
  return {
    facets: ['*'],
    hitsPerPage: SEARCH_PAGE_SIZE,
    attributesToSnippet: ['description:20'],
    attributesToRetrieve,
    filters: [
      algoliaQueryStringFilter('type', 'ORG'),
      ...searchAlgoliaOptionsFiltersForFacets({
        filtersById,
        searchFacets: searchFacetsOrg,
        searchLocation,
      }),
    ].join(' AND '),
    removeStopWords: true,
    ignorePlurals: true,
    advancedSyntax: true,
    queryLanguages: ['en', 'es', 'pt'],
  } satisfies SearchParamsObject;
}
