import {
  ListingApplicationPayload,
  NonAtsListingApplicationPayload,
} from 'api/ats';
import { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import { SalariesReportFormData } from 'modules/salaries/types/SalariesReportFormData';
import { ApiSavedSearchUnsaved } from 'modules/userDashboard/api/savedSearch/types/UserDashboardApiSavedSearch';

import { postJson } from './http/postJson';

export type SignupBlockRedirect = {
  redirectUrl: string;
};

export type SaveListingType = {
  id: string;
  type: string;
};

export type SubscribeSubsiteType = {
  subscribedSubsiteId: string;
};

export type StateType =
  | ListingApplicationPayload
  | ApiSavedSearchUnsaved
  | CmsApiSubscribe
  | SignupBlockRedirect
  | SaveListingType
  | SubscribeSubsiteType
  | SalariesReportFormData;

export type ActionType =
  | 'CMS_SUBSCRIBE'
  | 'SURVEY_REPORT_DATA'
  | 'LISTING_APPLICATION_VALUES'
  | 'SAVE_SEARCH'
  | 'SAVE_LISTING'
  | 'SIGNUP_BLOCK_REDIRECT'
  | 'SUBSCRIBE_SUBSITE';

export type TemporaryStorage = Record<ActionType, StateType>;

type ListingApplicationValuesAction = {
  type: 'LISTING_APPLICATION_VALUES';
  data: ListingApplicationPayload | NonAtsListingApplicationPayload;
};

type SaveSearchAction = {
  type: 'SAVE_SEARCH';
  data: ApiSavedSearchUnsaved;
};

type CmsSubscribeAction = {
  type: 'CMS_SUBSCRIBE';
  data: CmsApiSubscribe;
};

type SignupBlockRedirectAction = {
  type: 'SIGNUP_BLOCK_REDIRECT';
  data: SignupBlockRedirect;
};

type SaveListingAction = {
  type: 'SAVE_LISTING';
  data: SaveListingType;
};

type SusbscribeSubsiteAction = {
  type: 'SUBSCRIBE_SUBSITE';
  data: SubscribeSubsiteType;
};

type SurveyReportDataAction = {
  type: 'SURVEY_REPORT_DATA';
  data: SalariesReportFormData;
};

export type Action =
  | ListingApplicationValuesAction
  | SaveSearchAction
  | CmsSubscribeAction
  | SignupBlockRedirectAction
  | SaveListingAction
  | SusbscribeSubsiteAction
  | SurveyReportDataAction;

export async function saveTemporaryStorage(actions: Action[]) {
  return postJson<{ data: { id: string } }>('/data/tmp-storage', {
    data: JSON.stringify(actions),
  }).then(({ data: { id } }) => id);
}

export function generateRedirectUrl(redirectTo: string, tmpId: string | null) {
  if (tmpId) {
    const param = redirectTo.includes('?') ? '&tmpId' : '?tmpId';
    return `${redirectTo}${param}=${tmpId}`;
  }

  return redirectTo;
}

export async function saveActionsToTmpStorage({
  cmsSubscribe,
  savedListing,
  savedSearch,
  subscribedSubsite,
}: {
  cmsSubscribe?: CmsApiSubscribe;
  savedListing?: { id: string; type: string };
  savedSearch?: ApiSavedSearchUnsaved;
  subscribedSubsite?: string | null | undefined;
}): Promise<string | null> {
  const actions: Action[] = [];
  if (savedListing) {
    actions.push({ type: 'SAVE_LISTING', data: savedListing });
  }

  if (savedSearch) {
    actions.push({ type: 'SAVE_SEARCH', data: savedSearch });
  }

  if (cmsSubscribe) {
    actions.push({ type: 'CMS_SUBSCRIBE', data: cmsSubscribe });
  }

  if (subscribedSubsite) {
    actions.push({
      type: 'SUBSCRIBE_SUBSITE',
      data: { subscribedSubsiteId: subscribedSubsite },
    });
  }
  if (actions.length === 0) {
    return null;
  }
  const tmpId = await saveTemporaryStorage(actions);
  return tmpId;
}
