import { useAbExperimentBucket } from 'hooks/abExperiment/useAbExperimentBucket';
import { SearchFilters } from 'modules/search/components/Filters/SearchFilters';

import { EducationFilter } from './filters/EducationFilter';
import { EmploymentTypeFilter } from './filters/EmploymentTypeFilter';
import { JobFamilyFilter } from './filters/JobFamilyFilter';
import { JobKeywordsFilter } from './filters/JobKeywordsFilter';
import { LocaleFilter } from './filters/LocaleFilter';
import { LocationTypeFilter } from './filters/LocationTypeFilter';
import { OrgAreasOfFocusFilter } from './filters/OrgAreasOfFocusFilter';
import { OrgTypeFilter } from './filters/OrgTypeFilter';
import { ProfessionalLevelFilter } from './filters/ProfessionalLevelFilter';
import { RadiusFilter } from './filters/RadiusFilter';
import { RecencyFilter } from './filters/RecencyFilter';

export function SearchJobsPageFilters() {
  const [bucketValue] = useAbExperimentBucket(
    'SPLIT_SCREEN_SEARCH_INACTIVE_2024_12',
  );

  return bucketValue === 'ENABLED' ? (
    <SearchFilters>
      <JobFamilyFilter />
      <LocationTypeFilter />
      <EmploymentTypeFilter />
      <ProfessionalLevelFilter />
      <JobKeywordsFilter />
      <RecencyFilter />
      <EducationFilter />
      <OrgAreasOfFocusFilter />
      <LocaleFilter />
      <RadiusFilter />
      <OrgTypeFilter />
    </SearchFilters>
  ) : null;
}
