export const LOCATION_TYPE = {
  // TRANSLATORS: listing location type
  ONSITE: getText('On-site'),
  // TRANSLATORS: listing location type
  HYBRID: getText('Hybrid'),
  // TRANSLATORS: listing location type
  REMOTE: getText('Remote'),
};

export const LOCATION_TYPE_CHECKBOX_OPTIONS = Object.entries(LOCATION_TYPE).map(
  ([key, value]) => ({ name: key, label: value }),
);
