import { ChangeEvent, useMemo, useRef, useState } from 'react';

import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';
import { InputIconAffix } from 'components/Input/affixes/InputIconAffix';
import { ScrollArea } from 'components/ScrollArea/ScrollArea';
import { Stack } from 'components/Stack/Stack';
import { VerticalSpacer } from 'components/VerticalSpacer/VerticalSpacer';
import { SearchJobsFilterKeys } from 'modules/search/constants/filters/searchJobsFilters';
import { useSearchFacetLabelsWithCounts } from 'modules/search/hooks/filters/useSearchFacetLabelsWithCounts';
import { useSearchFacetReset } from 'modules/search/hooks/filters/useSearchFacetReset';
import { useSearchFacetToggleOption } from 'modules/search/hooks/filters/useSearchToggleFacetOption';
import { spacing } from 'theme/theme';

import {
  SearchFilterFacetOptionInput,
  SearchFilterFacetOptionLabel,
  SearchFilterFacetOptionsHolder,
} from './SearchFilterFacetOptions.styled';

export type Props = {
  facetType: SearchJobsFilterKeys;
  searchable?: boolean;
  initialValue?: string[] | string;
  options: { name: string; label: string }[];
};

export function SearchFilterCheckboxFacetOptions({
  initialValue = [],
  facetType,
  searchable,
  options,
}: Props) {
  const resetFacet = useSearchFacetReset(facetType);
  // @ts-expect-error Fix Type
  const toggleFacet = useSearchFacetToggleOption(facetType);

  const filterSearchRef = useRef<HTMLInputElement | null>(null);
  const [filterValue, setFilterValue] = useState('');

  const renderedOptions = useMemo(
    () =>
      searchable
        ? options.filter((option) =>
            option.label.toLowerCase().includes(filterValue.toLowerCase()),
          )
        : options,
    [searchable, filterValue, options],
  );

  const renderedOptionsWithFacetCounts = useSearchFacetLabelsWithCounts(
    facetType,
    renderedOptions,
  );

  return (
    <SearchFilterFacetOptionsHolder>
      <VerticalSpacer desktop={spacing[16]} />
      {searchable ? (
        <Input
          aria-label="search filter options"
          inputProps={{
            value: filterValue,
            onChange: (e) => setFilterValue(e.target.value),
            placeholder: 'Search',
            ref: filterSearchRef,
            type: 'text',
            inputMode: 'search',
          }}
          prefix={<InputIconAffix iconName="search" noPaddingRight />}
          affixVariant="transparent"
        />
      ) : null}
      <ScrollArea
        direction="vertical"
        height="290px"
        showScrollShadowStart={searchable}
        showScrollShadowEnd
        scrollShadowSize={spacing[24]}
      >
        <Stack direction="column" spacing={spacing[8]}>
          {renderedOptionsWithFacetCounts.map((option) => (
            <SearchFilterFacetOptionLabel key={option.label}>
              <SearchFilterFacetOptionInput
                defaultChecked={initialValue.includes(option.name)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  toggleFacet(e.target.name)
                }
                value={option.name}
                name={option.name}
                type="checkbox"
              />
              {option.label}
            </SearchFilterFacetOptionLabel>
          ))}
        </Stack>
      </ScrollArea>
      <Button variant="secondary" type="button" onClick={resetFacet}>
        {getText('Reset')}
      </Button>
    </SearchFilterFacetOptionsHolder>
  );
}
