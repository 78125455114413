import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router';

import { useLatest } from 'hooks/useLatest';
import { SearchJobsFilterKeys } from 'modules/search/constants/filters/searchJobsFilters';

export function useSearchFiltersOverflow() {
  const overflowFiltersRef = useRef<HTMLDivElement | null>(null);
  const [visibleFilters, setVisibleFilters] = useState<SearchJobsFilterKeys[]>(
    [],
  );

  const [searchParams] = useSearchParams();

  const resizeTimeoutIdRef = useLatest<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const updateVisibleFilters = () => {
      if (!overflowFiltersRef.current) return;

      const filterNodes =
        overflowFiltersRef.current.querySelectorAll('[data-facet-type]');
      const newVisibleFilters: SearchJobsFilterKeys[] = [];

      filterNodes.forEach((node) => {
        node.setAttribute('data-hidden', 'true');
      });

      filterNodes.forEach((node) => {
        const filterType = node.getAttribute(
          'data-facet-type',
        ) as SearchJobsFilterKeys;

        const filterAccordion = node.closest('details');

        if (!filterAccordion) return;

        if (!filterType || getComputedStyle(filterAccordion).display === 'none')
          return;
        newVisibleFilters.push(filterType);
        node.setAttribute('data-hidden', 'false');
      });

      if (
        newVisibleFilters.length !== visibleFilters.length ||
        newVisibleFilters.some(
          (filter, index) => filter !== visibleFilters[index],
        )
      ) {
        setVisibleFilters(newVisibleFilters);
      }
    };

    updateVisibleFilters();

    const observer = new MutationObserver(updateVisibleFilters);
    if (overflowFiltersRef.current) {
      observer.observe(overflowFiltersRef.current, {
        attributes: true,
        childList: true,
        attributeFilter: ['style', 'checked'],
        subtree: true,
      });
    }

    window.addEventListener('resize', () => {
      if (resizeTimeoutIdRef.current) {
        clearTimeout(resizeTimeoutIdRef.current);
      }

      resizeTimeoutIdRef.current = setTimeout(() => updateVisibleFilters(), 20);
    });

    return () => {
      observer.disconnect();
    };
  }, [resizeTimeoutIdRef, visibleFilters]);

  const selectedFilterValues = useMemo(() => {
    if (!overflowFiltersRef.current) return 0;

    return visibleFilters.reduce(
      (acc: number, filterType: SearchJobsFilterKeys) => {
        let newAcc = acc;
        newAcc += searchParams.getAll(filterType).length;
        return newAcc;
      },
      0,
    );
  }, [visibleFilters, searchParams]);

  return {
    overflowFiltersRef,
    selectedFilterValues,
    visibleFilters,
  };
}
