import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterRadioButtonFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterRadioButtonFacetOptions';
import { useSearchFilterSelectedValue } from 'modules/search/hooks/filters/useSearchFilterSelectedValue';
import { useSearchRadiusOptions } from 'modules/search/hooks/filters/useSearchRadiusOptions';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import {
  useSearchLocation,
  useSearchSort,
} from 'modules/search/zustand-stores/searchStore';
import { getDefaultSearchRadius } from 'utils/constants/general/defaultSearchRadius';

type Props = {
  variant?: SearchFilterVariant;
};

export function RadiusFilter({ variant = 'dropdown' }: Props) {
  const searchLocation = useSearchLocation();

  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  const sort = useSearchSort();
  const options = useSearchRadiusOptions();
  const selectedFilterValue = useSearchFilterSelectedValue('radius');

  if (!searchLocation || !searchLocation.hasGeo) return null;

  return (
    <FilterVariant
      icon="bulls-eye"
      facetType="radius"
      label={getText('Radius')}
    >
      <SearchFilterRadioButtonFacetOptions
        facetType="radius"
        initialValue={selectedFilterValue || getDefaultSearchRadius(sort)}
        options={options}
      />
    </FilterVariant>
  );
}
