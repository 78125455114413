import {
  Children,
  PropsWithChildren,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

import { AccordionItem } from 'components/Accordion/Item/AccordionItem';
import { DropdownChevron } from 'components/Dropdown/Chevron/DropdownChevron';
import { SearchJobsFilterKeys } from 'modules/search/constants/filters/searchJobsFilters';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';

import { SearchFilterAccordionSummary } from './SearchFilterAccordionItem.styled';

type Props = {
  facetType: SearchJobsFilterKeys;
  label: ReactNode;
};

export function SearchFilterAccordionItem({
  children,
  facetType,
  label,
}: PropsWithChildren<Props>) {
  return (
    <AccordionItem
      name="search-filter"
      header={
        <SearchFilterAccordionSummary>
          <span>{label}</span>
          <DropdownChevron size={16} />
        </SearchFilterAccordionSummary>
      }
    >
      <div data-facet-type={facetType}>
        {isValidElement<{ variant: SearchFilterVariant }>(children)
          ? Children.only(cloneElement(children, { variant: 'accordion' }))
          : null}
      </div>
    </AccordionItem>
  );
}
