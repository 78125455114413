import styled from 'styled-components';

import { Spacing, cssBreakpoints } from 'theme/theme';

const VerticalSpacerHolder = styled.div<{
  $desktop: Spacing | undefined;
  $mobile: Spacing | undefined;
}>`
  height: ${(props) => `${props.$desktop || 0}`};

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    height: ${(props) => `${props.$mobile || 0}`};
  }
`;

type Props = {
  desktop?: Spacing;
  mobile?: Spacing;
};

export function VerticalSpacer({ desktop, mobile }: Props) {
  return <VerticalSpacerHolder $desktop={desktop} $mobile={mobile} />;
}
