import styled from 'styled-components';

import { colors, cssBreakpoints, spacing } from 'theme/theme';

const SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH = '165px';

export const SearchFilterDropdownHolder = styled.div`
  cursor: pointer;
  order: 1;

  && menu {
    border-radius: 8px;
    padding: ${spacing[24]};
  }

  /* We need to select the Tooltip container 
  * and make sure we don't also select
  * the rendered tooltip when it displays,
  * hence :first-child. This will be removed 
  * when we replace the legacy job family facet
  */
  &[data-facet-type='synonymId'] > div > div > div:first-child {
    max-width: 133px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h5 {
    color: ${colors.brandBlue};
    line-height: 1.875;
    margin-bottom: 0;
  }

  &[open],
  &:hover > details > summary > span {
    background-color: ${colors.selectionGrey};
  }

  &[data-facet-state='PINNED'] summary > span,
  &[data-facet-state='QUEUED']:has(input:checked:not([value='']))
    summary
    > span {
    background-color: ${colors.selectionBlue};
  }

  &[data-facet-state='PINNED'],
  &[data-facet-state='QUEUED']:not(:has(input:checked:not([value='']))) {
    order: 0;
  }

  /* This is saying: Does the overflow facet have a facet count displayed
   * in the accordion header? If so, make the overflow facet "active" */
  &:last-child:is(:not(:first-child)):has(summary > span > span > span)
    summary
    > span {
    background-color: ${colors.selectionBlue};
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 6)) {
    /* Handle hiding the overflow menu if all facets are visible */
    &:last-child:not(:has([data-hidden='false'])) {
      display: none;
    }
  }
`;

// @NOTE: Will be removed when Job Family facet is modified
export const JobFamilyFilterCloseButton = styled.div`
  color: ${colors.selectionGrey};
  height: 16px;
  width: 16px;
`;
