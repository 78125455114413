import { ApiAddress } from 'api/types/ApiAddress';
import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { ApiInternshipFunction } from 'api/types/ApiInternshipFunction';
import { ApiRemoteOptions } from 'api/types/ApiRemoteOptions';
import { ApiRequestedAttachment } from 'api/types/ApiRequestedAttachment';
import { ApiISODateString } from 'api/types/ApiTypedDate';
import { ApiInternshipId, ApiOrgId } from 'api/types/ApiTypedId';
import { CorpRole } from 'utils/constants/general/corpRoles';
import { getJson } from 'utils/http/getJson';
import { postJson } from 'utils/http/postJson';
import { putJson } from 'utils/http/putJson';

import { OrgDashboardApiInternship } from './types/OrgDashboardApiInternship';

export type CreateInternshipPayload = {
  name: string;
  hasOrgNameOverride: boolean;
  orgNameOverride: string | null;
  orgLogoOverride: ApiFilestackImageData | null;
  isPostedAnonymously: boolean;
  description: string;
  locale: UserLocale;
  functions: ApiInternshipFunction[];
  hasAts: boolean;
  requestedAttachments: ApiRequestedAttachment[];
  applyAck: string | null;
  applyEmail: string | null;
  applyUrl: string | null;
  applyText: string | null;
  academicCredits: boolean;
  benefits: string | null;
  wage: string | null;
  hoursPerWeek: string | null;
  applicationDeadline: ApiISODateString | null;
  startDate: ApiISODateString | null;
  endDate: ApiISODateString | null;
  publish: boolean;
  corpRole: CorpRole | null;
  department: string | null;
  supervisorTitle: string | null;
  sharedValuePercentage: number | null;
  repostFromId: ApiInternshipId | null;
  remoteOptions: ApiRemoteOptions[];
  addresses: ApiAddress[];
  inAccordance: boolean;
};

export type EditInternshipPayload = {
  name: string;
  hasOrgNameOverride: boolean;
  orgNameOverride: string | null;
  orgLogoOverride: ApiFilestackImageData | null;
  isPostedAnonymously: boolean;
  description: string;
  locale: UserLocale;
  functions: ApiInternshipFunction[];
  hasAts: boolean;
  requestedAttachments: ApiRequestedAttachment[];
  applyAck: string | null;
  applyEmail: string | null;
  applyUrl: string | null;
  applyText: string | null;
  academicCredits: boolean;
  benefits: string | null;
  wage: string | null;
  hoursPerWeek: string | null;
  applicationDeadline: ApiISODateString | null;
  startDate: ApiISODateString | null;
  endDate: ApiISODateString | null;
  publish: boolean;
  corpRole: CorpRole | null;
  department: string | null;
  supervisorTitle: string | null;
  sharedValuePercentage: number | null;
  remoteOptions: ApiRemoteOptions;
  address: ApiAddress;
};

export function orgDashboardApiFetchInternship(
  orgId: ApiOrgId,
  internshipId: ApiInternshipId,
): Promise<OrgDashboardApiInternship> {
  return getJson<{ internship: OrgDashboardApiInternship }>(
    `/data/orgdashboard/${orgId}/internships/${internshipId}`,
  ).then(({ internship }) => internship);
}

export async function createInternship(
  orgId: ApiOrgId,
  payload: CreateInternshipPayload,
): Promise<{
  listings: OrgDashboardApiInternship[];
  requiresPayment: boolean;
}> {
  const { internships, requiresPayment } = await postJson<{
    internships: OrgDashboardApiInternship[];
    requiresPayment: boolean;
  }>(`/data/orgdashboard/${orgId}/internships`, payload);

  return { listings: internships, requiresPayment };
}

export function validateInternship(
  orgId: ApiOrgId,
  payload: Partial<CreateInternshipPayload>,
): Promise<void> {
  return postJson(
    `/data/orgdashboard/${orgId}/internships-validation`,
    payload,
  );
}

export function updateInternship(
  orgId: ApiOrgId,
  internshipId: ApiInternshipId,
  payload: EditInternshipPayload,
): Promise<{
  internship: OrgDashboardApiInternship;
  requiresPayment: boolean;
  hasInvoice: true;
}> {
  return putJson(
    `/data/orgdashboard/${orgId}/internships/${internshipId}`,
    payload,
  );
}

export function markInternshipActivityViewed(
  orgId: ApiOrgId,
  internshipId: ApiInternshipId,
): Promise<OrgDashboardApiInternship> {
  return postJson<{ internship: OrgDashboardApiInternship }>(
    `/data/orgdashboard/${orgId}/internships/${internshipId}/mark-activity-viewed`,
  ).then((data) => data.internship);
}
