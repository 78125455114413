import { useEffect } from 'react';

import { trackPageView } from 'utils/analytics/track/trackPageView';

import { useLatest } from './useLatest';

export function useTrackPageView(
  title: string,
  analyticsProperties?: Record<string, unknown>,
) {
  const analyticsPropertiesRef = useLatest(analyticsProperties);

  useEffect(() => {
    trackPageView(title, analyticsPropertiesRef.current);
  }, [analyticsPropertiesRef, title]);
}
