import styled from 'styled-components';

export const ScrollAreaHolder = styled.div<{
  $direction: 'vertical' | 'horizontal';
}>`
  position: relative;
`;

export const ScrollAreaViewport = styled.div<{
  $height: string;
  $hideScrollBar: boolean;
  $direction: 'vertical' | 'horizontal';
  $width: string;
  $scrollShadowSize: string;
  $showScrollShadowStart?: boolean;
  $showScrollShadowEnd?: boolean;
}>`
  max-height: ${({ $height }) => $height};
  max-width: ${({ $width }) => $width};

  ${({ $direction }) =>
    $direction === 'horizontal'
      ? `
        overflow-x: auto;
        `
      : `overflow-y: auto;`}

  ${({ $hideScrollBar }) =>
    $hideScrollBar
      ? `
      scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    `
      : undefined};

  ${({ $direction, $showScrollShadowStart, $scrollShadowSize }) =>
    $showScrollShadowStart
      ? `
      padding-top: ${$scrollShadowSize};

      &::before {
        content: '';
        position: absolute;
        left: 0;
        z-index: 1;
        width: ${$direction === 'horizontal' ? $scrollShadowSize : '100%'};
        height: ${$direction === 'horizontal' ? '100%' : $scrollShadowSize};
        top: 0;
        background: linear-gradient(
                        to ${$direction === 'horizontal' ? 'left' : 'top'},
                    rgba(251, 251, 251, 23.63%), rgba(255, 255, 255, 86.77%));
      }`
      : undefined}

  ${({ $direction, $showScrollShadowEnd, $scrollShadowSize }) =>
    $showScrollShadowEnd
      ? `
          padding-bottom: ${$scrollShadowSize};

          &::after {
            content: '';
            position: absolute;
            left: ${$direction === 'horizontal' ? 'auto' : 0};
            right: ${$direction === 'horizontal' ? 0 : 'auto'};
            z-index: 1;
            width: ${$direction === 'horizontal' ? $scrollShadowSize : '100%'};
            height: ${$direction === 'horizontal' ? '100%' : $scrollShadowSize};
            bottom: 0;
            background: linear-gradient(
                            to ${$direction === 'horizontal' ? 'right' : 'bottom'},
                            rgba(251, 251, 251, 23.63%), rgba(255, 255, 255, 86.77%));
            );
          }`
      : undefined}
`;
