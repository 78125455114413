import { MouseEvent, PropsWithChildren, ReactNode } from 'react';

import { ViewportBreakpoint } from 'theme/theme';

import {
  PillHolder,
  PillPrefixHolder,
  PillSuffixHolder,
  PillText,
} from './Pill.styled';
import { PillSize } from './types/PillSize';

type Props = {
  'aria-label'?: string;
  qaId?: string;
  title?: string;
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
  prefix?: ReactNode;
  size?: PillSize | Partial<Record<ViewportBreakpoint, PillSize>>;
  suffix?: ReactNode;
  variant?: 'default' | 'outline';
};

export function Pill({
  children,
  title,
  onClick,
  'aria-label': ariaLabel,
  qaId,
  prefix,
  size = 'small',
  suffix,
  variant = 'default',
}: PropsWithChildren<Props>) {
  return (
    <PillHolder
      aria-label={ariaLabel || title}
      data-clickable={onClick ? 'true' : undefined}
      data-variant={variant}
      $size={size}
      data-qa-id={qaId}
      title={title}
      onClick={onClick}
    >
      <PillPrefixHolder>{prefix}</PillPrefixHolder>
      <PillText>{children}</PillText>
      <PillSuffixHolder>{suffix}</PillSuffixHolder>
    </PillHolder>
  );
}
