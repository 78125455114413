import {
  Children,
  PropsWithChildren,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';

import { DropdownChevron } from 'components/Dropdown/Chevron/DropdownChevron';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { DropdownMenu } from 'components/Dropdown/menus/Menu/DropdownMenu';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { Pill } from 'components/Pill/Pill';
import { H5 } from 'components/Text/H5';
import { SearchJobsFilterKeys } from 'modules/search/constants/filters/searchJobsFilters';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { spacing } from 'theme/theme';

import { SearchFilterDropdownHolder } from './SearchFilterDropdown.styled';
import { useSearchFilterDropdown } from './useSearchFilterDropdown';

type Props = {
  facetType: SearchJobsFilterKeys;
} & {
  icon: IconName;
  label: ReactNode;
};

export function SearchFilterDropdown({
  children,
  facetType,
  icon,
  label,
}: PropsWithChildren<Props>) {
  const { filterState, holderRef, toggleDropdown } =
    // @ts-expect-error Fix type
    useSearchFilterDropdown(facetType);

  return (
    <SearchFilterDropdownHolder ref={holderRef} data-facet-state={filterState}>
      <Dropdown
        onToggle={toggleDropdown}
        trigger={
          <Pill
            size="medium"
            prefix={icon ? <Icon size={18} name={icon} /> : undefined}
            suffix={<DropdownChevron size={16} />}
          >
            {label}
          </Pill>
        }
      >
        <DropdownMenu offsetY={spacing[8]} placement="bottom-left">
          <H5>{label}</H5>
          <div data-facet-type={facetType}>
            {isValidElement<{ variant: SearchFilterVariant }>(children)
              ? Children.only(cloneElement(children, { variant: 'dropdown' }))
              : children}
          </div>
        </DropdownMenu>
      </Dropdown>
    </SearchFilterDropdownHolder>
  );
}
