import { useUnsignedPageVisitCounter } from 'hooks/usePageVisitCounter';
import { useQueuedTrackers } from 'hooks/useQueuedTrackers';
import { useRouterHashScroll } from 'hooks/useRouterHashScroll';
import { useShowUserModals } from 'hooks/useShowUserModals';
import { useTemporaryStorageHandler } from 'hooks/useTemporaryStorageHandler';
import { useTrackNavigationState } from 'hooks/useTrackNavigationState';

/*
 * Wrap app hooks to prevent them from re-rendering the whole tree
 */
export function AppHooks() {
  useRouterHashScroll();
  useTemporaryStorageHandler();
  useQueuedTrackers();
  useTrackNavigationState();
  useShowUserModals();
  useUnsignedPageVisitCounter();

  return null;
}
