import {
  Children,
  PropsWithChildren,
  cloneElement,
  isValidElement,
} from 'react';

import { Accordion } from 'components/Accordion/Accordion';
import { TextBadge } from 'components/Badge/TextBadge';
import { DropdownChevron } from 'components/Dropdown/Chevron/DropdownChevron';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { DropdownMenu } from 'components/Dropdown/menus/Menu/DropdownMenu';
import { Icon } from 'components/Icon/Icon';
import { Pill } from 'components/Pill/Pill';
import { ScrollArea } from 'components/ScrollArea/ScrollArea';
import { H5 } from 'components/Text/H5';
import { SearchFilterDropdownHolder } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown.styled';
import { useSearchFiltersOverflow } from 'modules/search/hooks/filters/useSearchFacetsOverflow';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { spacing } from 'theme/theme';

export function SearchFiltersOverflowDropdown({ children }: PropsWithChildren) {
  const { overflowFiltersRef, selectedFilterValues } =
    useSearchFiltersOverflow();

  return (
    <SearchFilterDropdownHolder ref={overflowFiltersRef}>
      <Dropdown
        trigger={
          <Pill
            size="medium"
            prefix={<Icon size={18} name="plus" />}
            suffix={<DropdownChevron size={16} />}
          >
            {getText('More Filters')}{' '}
            <TextBadge content={selectedFilterValues} />
          </Pill>
        }
      >
        <DropdownMenu
          offsetY={spacing[8]}
          placement="bottom-left"
          width="350px"
        >
          <Accordion header={<H5>{getText('More Filters')}</H5>}>
            <ScrollArea
              height="calc(100vh - 211px)"
              direction="vertical"
              hideScrollBar
            >
              {Children.map(children, (child) => {
                if (
                  !isValidElement<{
                    variant: SearchFilterVariant;
                  }>(child)
                ) {
                  return child;
                }

                return cloneElement(child, {
                  variant: 'accordion',
                });
              })}
            </ScrollArea>
          </Accordion>
        </DropdownMenu>
      </Dropdown>
    </SearchFilterDropdownHolder>
  );
}
