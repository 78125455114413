export const SEARCH_JOBS_FILTERS = [
  'areasOfFocus',
  'education',
  'functions',
  'jobType',
  'locale',
  'locationType',
  'orgType',
  'professionalLevel',
  'radius',
  'recency',
  'synonymId',
] as const;

export type SearchJobsFilterKeys = (typeof SEARCH_JOBS_FILTERS)[number];
