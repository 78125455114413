import { SearchParamsObject } from '@algolia/client-search';

import { SearchJobsFilterKeys } from 'modules/search/constants/filters/searchJobsFilters';
import { SearchFiltersById } from 'modules/search/types/SearchFiltersById';
import { SearchLocation } from 'modules/search/types/SearchLocation';

import { searchAlgoliaEventsOptions } from './searchAlgoliaEventsOptions';
import { searchAlgoliaInternshipsOptions } from './searchAlgoliaInternshipsOptions';
import { searchAlgoliaJobsOptions } from './searchAlgoliaJobsOptions';
import { searchAlgoliaOrgsOptions } from './searchAlgoliaOrgsOptions';
import { searchAlgoliaVolopsOptions } from './searchAlgoliaVolopsOptions';

export function searchAlgoliaOptionsSwitch({
  facets,
  filtersById,
  searchLocation,
}: {
  facets?: SearchJobsFilterKeys[];
  filtersById: SearchFiltersById;
  searchLocation: SearchLocation | undefined;
}): SearchParamsObject {
  switch (filtersById.type) {
    case 'JOB':
      return searchAlgoliaJobsOptions({ facets, filtersById, searchLocation });
    case 'VOLOP':
      return searchAlgoliaVolopsOptions({ filtersById, searchLocation });
    case 'EVENT':
      return searchAlgoliaEventsOptions({ filtersById, searchLocation });
    case 'INTERNSHIP':
      return searchAlgoliaInternshipsOptions({ filtersById, searchLocation });
    case 'ORG':
      return searchAlgoliaOrgsOptions({ filtersById, searchLocation });
  }
}
