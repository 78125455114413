import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { H1 } from 'components/Text/H1';
import { useTrackPageView } from 'hooks/useTrackPageView';
import { CmsApiPagePage } from 'modules/cms/api/types/CmsApiPage';
import { CmsBlockContainerWithDividers } from 'modules/cms/components/BlocksWithContainer/CmsBlockContainerWithDividers';
import {
  CmsBodyHolder,
  CmsBodyHolderWidth,
} from 'modules/cms/components/BodyHolder/CmsBodyHolder';
import { CmsHeaderImage } from 'modules/cms/components/HeaderImage/CmsHeaderImage';
import { CmsListingCountsLoadable } from 'modules/cms/components/ListingCounts/CmsListingCountsLoadable';
import { getContentFirstRealBlockIndex } from 'modules/cms/helpers/getContentFirstRealBlockIndex';
import { getContentHasBodyHeader } from 'modules/cms/helpers/getContentHasBodyHeader';
import { getContentListingCounts } from 'modules/cms/helpers/getContentListingCounts';
import { getContentShowListingCounts } from 'modules/cms/helpers/getContentShowListingCounts';
import { getIsContentFullWidthLayout } from 'modules/cms/helpers/getIsContentFullWidthLayout';
import { getNextRealBlock } from 'modules/cms/helpers/getNextRealBlock';
import { getPrevRealBlock } from 'modules/cms/helpers/getPrevRealBlock';
import { mapCmsBlocksForBody } from 'modules/cms/mappers/mapCmsBlocksForBody';
import { gutterWidth, sectionPadding } from 'theme/theme';

const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;

type Props = {
  content: CmsApiPagePage;
};

export function CmsPagePageBody({ content }: Props) {
  const fullWidthLayout = getIsContentFullWidthLayout({ content });

  const { subsite } = content;

  useTrackPageView('CMS Page', {
    subsite_name: subsite?.publicName,
    page_title: content.title,
    page_type: content.type,
    categories: [],
  });

  const headerImage = content.headerImage && (
    <CmsHeaderImage content={content} />
  );

  const showListingCounts = getContentShowListingCounts({ content });
  const listingCounts = getContentListingCounts({ content });
  const hasHeader = getContentHasBodyHeader({ content });
  const firstRealBlockIndex = getContentFirstRealBlockIndex({ content });

  const mappedBlocks = mapCmsBlocksForBody({ content });

  const firstRealBlock = mappedBlocks[firstRealBlockIndex];
  const firstRealBlockHasBackground = Boolean(
    mappedBlocks?.length && firstRealBlock?.hasBackground,
  );

  const headerBodyHolderWidth =
    content.layout === 'NARROW'
      ? CmsBodyHolderWidth.NARROW_LAYOUT
      : CmsBodyHolderWidth.PAGE_LAYOUT;

  return (
    <Box component="main" ml="auto" mr="auto" data-qa-id="content-container">
      {(hasHeader || headerImage || showListingCounts) && (
        <header>
          {hasHeader && (
            <Box m={sectionPadding}>
              <CmsBodyHolder $width={headerBodyHolderWidth}>
                <H1 m="0" textAlign={fullWidthLayout ? 'center' : 'left'}>
                  {content.title}
                </H1>
              </CmsBodyHolder>
            </Box>
          )}

          {headerImage && (
            <Box m={sectionPadding}>
              <CmsBodyHolder $width={headerBodyHolderWidth}>
                {headerImage}
              </CmsBodyHolder>
            </Box>
          )}

          {showListingCounts && listingCounts && (
            <CmsListingCountsLoadable
              darkMode={firstRealBlockHasBackground}
              listingCounts={listingCounts}
            />
          )}
        </header>
      )}

      <Content
        data-qa-id="cms-main-content"
        data-qa-content-layout={content.layout}
      >
        {mappedBlocks.map((mappedBlock, index) => {
          if (mappedBlock.type === 'ANCHOR') return null;

          return (
            <CmsBlockContainerWithDividers
              key={mappedBlock.id}
              mappedBlock={mappedBlock}
              prevBlock={getPrevRealBlock(mappedBlocks, index)}
              nextBlock={getNextRealBlock(mappedBlocks, index)}
              layout={content.layout}
              index={index}
              isLastBlock={index === mappedBlocks.length - 1}
            >
              {mappedBlock.component}
            </CmsBlockContainerWithDividers>
          );
        })}

        {content.hasRecommendedJobs && (
          <div style={{ marginTop: gutterWidth }} />
        )}
      </Content>
    </Box>
  );
}
