import { useLocation } from 'react-router';
import { sprintf } from 'sprintf-js';
import styled from 'styled-components';

import { Button } from 'components/Button/Button';
import { Pill } from 'components/Pill/Pill';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { SearchFilterDropdownHolder } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown.styled';
import { useSearchFacetReset } from 'modules/search/hooks/filters/useSearchFacetReset';
import { useSearchJobsRouteLoaderData } from 'modules/search/hooks/useSearchJobsRouteLoaderData';
import { colors, spacing } from 'theme/theme';

// As of now, this is the only place where the `synonymId` is stored as a filter
// https://github.com/idealist7/idealist7/blob/4afba44021034907e6d4f83589c36dcdfd8669c1/web/src/modals/EmailAlert/EmailAlertModalContainer.tsx#L75
//
// At some point, this will be converted into a `SearchFacetDropdown`

const SearchFilterCloseButton = styled(Button)`
  div[role='img'] {
    color: ${colors.lightContentGrey};
    margin-left: ${spacing[6]};
    padding: 0;
    position: relative;
    top: -1px;
    left: 3px;
  }
`;

export function JobFamilyFilter() {
  const location = useLocation();

  const { jobFamilyId, jobSynonymName } = useSearchJobsRouteLoaderData();

  const resetFacet = useSearchFacetReset('synonymId');

  const selectedFilterValue = location.search.includes('synonymId');

  if (!jobSynonymName || !jobFamilyId || !selectedFilterValue) return null;

  return (
    <SearchFilterDropdownHolder
      data-facet-state="PINNED"
      data-facet-type="synonymId"
    >
      <Pill
        suffix={
          <SearchFilterCloseButton
            data-qa-id="close-banner-ad-button"
            onClick={resetFacet}
            noPadding
            variant="link-grey"
            type="button"
            icon={{
              type: 'normalized',
              name: 'close',
              color: 'lightContentGrey',
              size: 16,
            }}
          />
        }
      >
        <Tooltip
          content={sprintf(getText(`Similar To: %(jobSynonymName)s`), {
            jobSynonymName,
          })}
          placement="bottom-center"
          variant="filled"
        >
          <span style={{ whiteSpace: 'nowrap' }}>
            {sprintf(getText(`Similar To: %(jobSynonymName)s`), {
              jobSynonymName,
            })}
          </span>
        </Tooltip>
      </Pill>
    </SearchFilterDropdownHolder>
  );
}
