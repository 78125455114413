import { useCallback } from 'react';
import { useLocation } from 'react-router';

import { useLatest } from 'hooks/useLatest';
import { searchGetTrackingData } from 'modules/search/helpers/analytics/searchGetTrackingData';

type LocationState = {
  searchFrom?: string;
};

export function useSearchAnalytics() {
  const location = useLocation<LocationState>();

  // Don't trigger analytics change just because the searchFrom value changed
  const searchFromRef = useLatest(location.state?.searchFrom);

  const getSearchTrackingData = useCallback(
    () => searchGetTrackingData({ searchFrom: searchFromRef.current }),
    [searchFromRef],
  );

  return { getSearchTrackingData };
}
