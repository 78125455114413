type Props = {
  content: string | number;
  showZero?: boolean;
};

export function TextBadge({ content, showZero }: Props) {
  if (!content || (content === 0 && !showZero)) return null;

  return <span>{`(${content})`}</span>;
}
