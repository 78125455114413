import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterRadioButtonFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterRadioButtonFacetOptions';
import { useSearchFilterSelectedValue } from 'modules/search/hooks/filters/useSearchFilterSelectedValue';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { RECENCY_PERIOD_RADIO_OR_SELECT_OPTIONS } from 'utils/constants/general/recency';

type Props = {
  variant?: SearchFilterVariant;
};
export function RecencyFilter({ variant = 'dropdown' }: Props) {
  const selectedFilterValue = useSearchFilterSelectedValue('recency');

  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  return (
    <FilterVariant facetType="recency" icon="clock" label={getText('Recency')}>
      <SearchFilterRadioButtonFacetOptions
        facetType="recency"
        initialValue={selectedFilterValue}
        options={RECENCY_PERIOD_RADIO_OR_SELECT_OPTIONS}
      />
    </FilterVariant>
  );
}
