import queryString from 'query-string';
import { useCallback } from 'react';
import { createSearchParams, useLocation, useSearchParams } from 'react-router';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { useLatest } from 'hooks/useLatest';
import { SearchContextHeaderLocationState } from 'modules/search/containers/SearchContextHeader/SearchContextHeaderLocationState';
import { useSearchAnalytics } from 'modules/search/hooks/useSearchAnalytics';
import { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import {
  setSearchLastUpdatedFilter,
  useSearchFiltersByName,
} from 'modules/search/zustand-stores/searchStore';
import { trackEvent } from 'utils/analytics/track/trackEvent';

export function useSearchFacetToggleOption(
  facetType: keyof SearchFiltersByName,
) {
  const location = useLocation<SearchContextHeaderLocationState>();
  const [searchParams] = useSearchParams();
  const searchFiltersByName = useSearchFiltersByName();
  const searchParamsRef = useLatest<URLSearchParams>(searchParams);

  const navigate = useDeferredNavigate();

  const { getSearchTrackingData } = useSearchAnalytics();

  const isListingPage = location.state?.showSearchContext;

  const toggle = useCallback(
    (name: string) => {
      const searchFacetSelectedValues = searchFiltersByName
        ? searchFiltersByName[facetType] || []
        : [];

      const filterSelected = searchFacetSelectedValues.includes(name);

      if (filterSelected) {
        searchParamsRef.current.delete(facetType, name as string);
        (
          document.querySelector(
            `[data-facet-type=${facetType}] input[name=${name}]`,
          ) as HTMLInputElement
        ).checked = false;
      } else {
        searchParamsRef.current.append(facetType, name as string);
        (
          document.querySelector(
            `[data-facet-type=${facetType}] input[name=${name}]`,
          ) as HTMLInputElement
        ).checked = true;
      }

      // Toggling a filter should always reset results to the first page
      if (searchParamsRef.current.has('page')) {
        searchParamsRef.current.delete('page');
      }

      trackEvent('Changed Search Filter', {
        filter_name: facetType,
        [filterSelected ? 'selected_value' : 'deselected_value']: name,
        ...getSearchTrackingData(),
      });

      // We use this to avoid updating the facet counts for the currently selected filter type
      // Becuase filter options within a filter are inclusive ("OR"), so the counts should not filter
      // Algolia counts facets based on returned search results for given filters,
      // so it would say there were 0 results for a given unselected filter type,
      // event if clicking it added some amount of results
      setSearchLastUpdatedFilter(facetType);

      let newSearchParams;

      if (isListingPage && searchFiltersByName) {
        if (filterSelected) {
          newSearchParams = queryString.stringify({
            ...searchFiltersByName,
            [facetType]: Array.isArray(searchFacetSelectedValues)
              ? searchFacetSelectedValues.filter((value) => value !== name)
              : name,
          });
        } else {
          newSearchParams = queryString.stringify({
            ...searchFiltersByName,
            [facetType]: Array.isArray(searchFacetSelectedValues)
              ? [...searchFacetSelectedValues, name]
              : [searchFacetSelectedValues, name],
          });
        }
      } else {
        newSearchParams = createSearchParams(searchParamsRef.current);
      }

      navigate(`${CURRENT_LOCALE}/jobs?${newSearchParams}`, { replace: true });
    },
    [
      facetType,
      getSearchTrackingData,
      isListingPage,
      navigate,
      searchFiltersByName,
      searchParamsRef,
    ],
  );

  return toggle;
}
