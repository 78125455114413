import { useEffect, useMemo, useState } from 'react';

import { ActionToastIcons } from 'components/ActionToast/ActionToast.styled';
import { useToastStore } from 'components/ActionToast/useActionToast';
import { useUnsignedPageVisitCounter } from 'hooks/usePageVisitCounter';
import { useUserHasPreviouslySignedIn } from 'hooks/useUserHasPreviouslySignedIn';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEventOnce } from 'utils/analytics/track/trackEventOnce';
import { showModal } from 'zustand-stores/modalStore';
import { useUser } from 'zustand-stores/userEnvironmentStore';

import { useSignInOrUpReminderPersistence } from './useSignInOrUpReminderPersistence';

// Note(@paprikka): SignUpReminder and SignInReminder are almost identical,
// except for some minor differences in the text and the toast type and display
// conditions.  We could abstract this out into a common component, but it's not
// worth it right now since it's a new feature and the requirements might change
// based on usage.

function SignUpReminder() {
  const { showToast, isDisplayingToast, dismissToast } = useToastStore();
  const { hasDismissedToast, persistDismissedToastId, getDismissal, clear } =
    useSignInOrUpReminderPersistence();
  const isLoggedIn = Boolean(useUser().user);

  const { getVisitCount } = useUnsignedPageVisitCounter();
  const pageVisitCount = getVisitCount();
  const [hasCheckedDates, setHasCheckedDates] = useState(false);

  useEffect(() => {
    setHasCheckedDates(true);
    const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
    const dismissal = getDismissal('unrecognised-user-toast');
    if (!dismissal) return;
    const age =
      Date.now() - getDismissal('unrecognised-user-toast').dismissedAt;
    if (age > ONE_MONTH) {
      clear();
    }
  }, [clear, getDismissal, hasCheckedDates]);

  const shouldDisplayToast = useMemo(() => {
    if (!hasCheckedDates) return false;
    if (isDisplayingToast('unrecognised-user-toast')) return false;
    if (pageVisitCount < 3) return false;
    if (hasDismissedToast('unrecognised-user-toast')) return false;

    return true;
  }, [hasCheckedDates, isDisplayingToast, pageVisitCount, hasDismissedToast]);

  useEffect(() => {
    if (!shouldDisplayToast) return;

    trackEventOnce('Displayed: Action Toast', {
      type: 'sign_up',
      current_page_url: window.location.href,
    });
    showToast({
      id: 'unrecognised-user-toast',
      headline: getText('Join Idealist for the best experience'),
      body: getText('Sign up for email alerts, save searches, and more!'),
      dismissible: true,
      duration: 15_000,
      variant: 'success',
      onDismiss: () => {
        persistDismissedToastId('unrecognised-user-toast');
        trackEventOnce('Dismissed: Action Toast', {
          type: 'sign_up',
          current_page_url: window.location.href,
        });
      },
      icon: ActionToastIcons.success(),
      actions: [
        {
          label: getText('Sign Up'),
          onClick: () => {
            persistDismissedToastId('unrecognised-user-toast');
            dismissToast('unrecognised-user-toast');
            trackClicked('Action Toast', {
              type: 'sign_up',
              current_page_url: window.location.href,
            });
            showModal('SIGNUP', {});
          },
          type: 'button',
        },
      ],
    });
  }, [dismissToast, persistDismissedToastId, shouldDisplayToast, showToast]);

  useEffect(() => {
    if (!isLoggedIn) return;
    dismissToast('unrecognised-user-toast');
  }, [isLoggedIn, dismissToast]);

  return null;
}

function SignInReminder() {
  const { showToast, isDisplayingToast, dismissToast } = useToastStore();
  const { hasDismissedToast, persistDismissedToastId, getDismissal, clear } =
    useSignInOrUpReminderPersistence();
  const isLoggedIn = Boolean(useUser().user);

  const { getVisitCount } = useUnsignedPageVisitCounter();
  const pageVisitCount = getVisitCount();

  const [hasCheckedDates, setHasCheckedDates] = useState(false);

  useEffect(() => {
    setHasCheckedDates(true);
    const ONE_MONTH = 30 * 24 * 60 * 60 * 1000;
    const dismissal = getDismissal('recognised-user-toast');
    if (!dismissal) return;
    const age = Date.now() - getDismissal('recognised-user-toast').dismissedAt;
    if (age > ONE_MONTH) {
      clear();
    }
  }, [clear, getDismissal, hasCheckedDates]);

  const shouldDisplayToast = useMemo(() => {
    if (!hasCheckedDates) return false;
    if (isDisplayingToast('recognised-user-toast')) return false;
    if (pageVisitCount < 3) return false;
    if (hasDismissedToast('recognised-user-toast')) return false;

    return true;
  }, [hasCheckedDates, isDisplayingToast, pageVisitCount, hasDismissedToast]);

  useEffect(() => {
    if (!shouldDisplayToast) return;

    trackEventOnce('Displayed: Action Toast', {
      type: 'log_in',
      current_page_url: window.location.href,
    });
    showToast({
      id: 'recognised-user-toast',
      headline: getText('Log in for the best experience'),
      body: getText('Sign in for email alerts, save searches, and more!'),
      dismissible: true,
      duration: 15_000,
      variant: 'warning',
      onDismiss: () => {
        persistDismissedToastId('recognised-user-toast');
        trackEventOnce('Dismissed: Action Toast', {
          type: 'log_in',
          current_page_url: window.location.href,
        });
      },
      icon: ActionToastIcons.success(),
      actions: [
        {
          label: getText('Log In'),
          onClick: () => {
            persistDismissedToastId('recognised-user-toast');
            dismissToast('recognised-user-toast');
            trackClicked('Action Toast', {
              type: 'log_in',
              current_page_url: window.location.href,
            });
            showModal('LOGIN', {});
          },
          type: 'button',
        },
      ],
    });
  }, [dismissToast, persistDismissedToastId, shouldDisplayToast, showToast]);

  useEffect(() => {
    if (!isLoggedIn) return;
    dismissToast('recognised-user-toast');
  }, [isLoggedIn, dismissToast]);

  return null;
}

export function SignInOrUpReminderContainerClientSide() {
  const isLoggedIn = Boolean(useUser().user);
  const hasPreviouslySignedIn = useUserHasPreviouslySignedIn();
  if (isLoggedIn) return null;
  return hasPreviouslySignedIn ? <SignInReminder /> : <SignUpReminder />;
}

export function SignInOrUpReminderContainer() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    // Note that we're agnostic of the actual value of the localStorage item, we
    // just want to know if it's set.
    const isDisabledForDebugPurposes =
      window.localStorage.getItem(
        'idealist:sign-in-or-up-reminder::disabled',
      ) || false;
    if (isDisabledForDebugPurposes) return;
    setIsMounted(true);
  }, []);
  return isMounted ? <SignInOrUpReminderContainerClientSide /> : null;
}
