import { useCallback, useRef } from 'react';

import { useSearchFacetSelectedValues } from 'modules/search/hooks/filters/useSearchFacetSelectedValues';
import { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';

export function useSearchFilterDropdown(facetType: keyof SearchFiltersByName) {
  const selectedFacetValues = useSearchFacetSelectedValues(facetType);
  const hasSelectedFacetValues = selectedFacetValues.length > 0;

  const holderRef = useRef<HTMLDivElement>(null);

  const dropdownIsOpenRef = useRef(false);
  const hasOtherOpenDropdownsRef = useRef(false);

  const filterStateRef = useRef(
    hasSelectedFacetValues &&
      !dropdownIsOpenRef.current &&
      !hasOtherOpenDropdownsRef.current
      ? 'PINNED'
      : 'UNPINNED',
  );

  const toggleDropdown = useCallback(
    (open: boolean) => {
      dropdownIsOpenRef.current = open;

      if (!holderRef.current) {
        throw new Error(
          `Search filter dropdown for ${facetType} does not exist.`,
        );
      }

      const { dataset } = holderRef.current;

      const filterIsPinned = dataset.facetState === 'PINNED';
      const filterIsUnpinned = dataset.facetState === 'UNPINNED';
      const otherOpenDropdowns = Array.from(
        document.querySelectorAll(
          `[data-facet-state] details[open]:not(:has([data-facet-type=${facetType}])`,
        ),
      );

      hasOtherOpenDropdownsRef.current = otherOpenDropdowns.length > 0;

      if (!open) {
        if (hasSelectedFacetValues && filterIsUnpinned) {
          dataset.facetState = 'QUEUED';

          if (!hasOtherOpenDropdownsRef.current) {
            const filtersToPin = [
              ...document.querySelectorAll(
                '[data-facet-state="QUEUED"]:has(input:checked:not([value=""]))',
              ),
            ] as HTMLDivElement[];

            filtersToPin.forEach((node) => {
              // eslint-disable-next-line no-param-reassign
              node.dataset.facetState = 'PINNED';
            });
          }
        } else if (!hasSelectedFacetValues && filterIsPinned) {
          dataset.facetState = 'QUEUED';

          if (!hasOtherOpenDropdownsRef.current) {
            const filtersToUnpin = [
              ...document.querySelectorAll(
                '[data-facet-state="QUEUED"]:not(:has(input:checked:not([value=""])))',
              ),
            ] as HTMLDivElement[];

            filtersToUnpin.forEach((node) => {
              // eslint-disable-next-line no-param-reassign
              node.dataset.facetState = 'UNPINNED';
            });
          }
        }
      }
    },
    [facetType, hasSelectedFacetValues],
  );

  return { holderRef, filterState: filterStateRef.current, toggleDropdown };
}
