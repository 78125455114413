import styled from 'styled-components';

import {
  detailsStateClosedClass,
  detailsStateOpenClass,
} from 'components/Details/detailsClasses';
import {
  detailsBrandBlueWhenOpenVar,
  detailsCalloutGreyWhenOpenVar,
  detailsOneWhenClosedMinusOneWhenOpenVar,
  detailsOneWhenClosedZeroWhenOpenVar,
  detailsSelectionGreyWhenOpenVar,
} from 'components/Details/detailsCssVariables';
import { colors, cssBreakpoints, transition } from 'theme/theme';

/**
 * Do not add `position: relative` to the details.
 *
 * In some cases, such as the mobile DropdownFoldMenu, we need the menu to align
 * with the parent container, not the `details` tag
 *
 * Instead, add `position: relative` to the desired wrapping element
 */
export const DropdownDetails = styled.details`
  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    position: relative;
  }

  display: inline-flex;

  ${detailsOneWhenClosedMinusOneWhenOpenVar}: 1;
  ${detailsOneWhenClosedZeroWhenOpenVar}: 1;

  &[open] {
    ${detailsOneWhenClosedMinusOneWhenOpenVar}: -1;
    ${detailsOneWhenClosedZeroWhenOpenVar}: 0;
    ${detailsBrandBlueWhenOpenVar}: ${colors.brandBlue};
    ${detailsCalloutGreyWhenOpenVar}: ${colors.calloutGrey};
    ${detailsSelectionGreyWhenOpenVar}: ${colors.selectionGrey};
  }

  &.${detailsStateOpenClass} {
    menu {
      transition: ${transition};
      transition-property: transform;
    }
  }

  &.${detailsStateClosedClass} {
    menu {
      visibility: hidden;

      @media all and (max-width: ${cssBreakpoints.mdDown}) {
        transform: translateY(-10%);
      }

      @media all and (min-width: ${cssBreakpoints.mdUp}) {
        transform: translateY(-10px);
      }
    }
  }
`;

export const DropdownSummary = styled.summary`
  position: relative;
  z-index: 100;

  &::marker {
    content: '';
  }

  &::-webkit-details-marker /* Safari */ {
    display: none;
  }
`;
