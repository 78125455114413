import { PropsWithChildren } from 'react';

import { Spacing, spacing } from 'theme/theme';

import { ScrollAreaHolder, ScrollAreaViewport } from './ScrollArea.styled';

type Props = {
  direction: 'vertical' | 'horizontal';
  height?: string;
  hideScrollBar?: boolean;
  qaId?: string;
  width?: string;
  scrollShadowSize?: Spacing;
  showScrollShadowEnd?: boolean;
  showScrollShadowStart?: boolean;
};

export function ScrollArea({
  children,
  direction,
  height = 'inherit',
  hideScrollBar = false,
  qaId,
  width = 'inherit',
  scrollShadowSize = spacing[30],
  showScrollShadowEnd,
  showScrollShadowStart,
}: PropsWithChildren<Props>) {
  return (
    <ScrollAreaHolder $direction={direction}>
      <ScrollAreaViewport
        data-qa-id={qaId}
        $direction={direction}
        $height={height}
        $hideScrollBar={hideScrollBar}
        $width={width}
        $scrollShadowSize={scrollShadowSize}
        $showScrollShadowStart={showScrollShadowStart}
        $showScrollShadowEnd={showScrollShadowEnd}
      >
        {children}
      </ScrollAreaViewport>
    </ScrollAreaHolder>
  );
}
