import { Button } from 'components/Button/Button';

import { ActionToastButtonAction } from './useActionToast';

export function ActionButton({ label, onClick }: ActionToastButtonAction) {
  return (
    <Button type="button" onClick={onClick}>
      {label}
    </Button>
  );
}
