import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterCheckboxFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterCheckboxFacetOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/filters/useSearchFacetSelectedValues';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { LOCATION_TYPE_CHECKBOX_OPTIONS } from 'utils/constants/general/locationType';

type Props = {
  variant?: SearchFilterVariant;
};

export function LocationTypeFilter({ variant = 'dropdown' }: Props) {
  // @ts-expect-error Fix type
  const selectedFilterValues = useSearchFacetSelectedValues('locationType');

  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  return (
    <FilterVariant
      facetType="locationType"
      icon="desk"
      label={
        <>
          {getText('Remote')}{' '}
          <TextBadge content={selectedFilterValues.length} />
        </>
      }
    >
      <SearchFilterCheckboxFacetOptions
        facetType="locationType"
        initialValue={selectedFilterValues}
        options={LOCATION_TYPE_CHECKBOX_OPTIONS}
      />
    </FilterVariant>
  );
}
