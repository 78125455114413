import { CSSProperties, PropsWithChildren, ReactNode } from 'react';

import { AccordionHolder } from './Accordion.styled';
import { accordionWidth } from './accordionCssVars';

type Props = {
  header?: ReactNode;
  qaId?: string;
  className?: string;
  width?: string;
};

export function Accordion({
  children,
  className,
  header,
  qaId,
  width = 'auto',
}: PropsWithChildren<Props>) {
  return (
    <AccordionHolder
      className={className}
      data-qa-id={qaId}
      style={{ [accordionWidth]: width } as CSSProperties}
    >
      {header}
      {children}
    </AccordionHolder>
  );
}
