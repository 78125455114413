import { useSearchParams } from 'react-router';

import { SearchFiltersByName } from 'modules/search/types/FiltersByName/SearchFiltersByName';
import { useSearchFiltersByName } from 'modules/search/zustand-stores/searchStore';

export function useSearchFacetSelectedValues(
  filterType: keyof SearchFiltersByName,
) {
  const [searchParams] = useSearchParams();
  const searchFiltersFromUrl = searchParams.getAll(filterType);
  const searchFiltersFromState = useSearchFiltersByName();

  const hasSearchFiltersFromState =
    searchFiltersFromState &&
    Array.isArray(Object.keys(searchFiltersFromState)) &&
    Object.keys(searchFiltersFromState).length > 0 &&
    !(
      Object.keys(searchFiltersFromState).length === 1 &&
      Object.keys(searchFiltersFromState).includes('type')
    );

  if (hasSearchFiltersFromState) {
    const searchFacetSelectedValues = searchFiltersFromState[filterType] || [];

    return Array.isArray(searchFacetSelectedValues)
      ? searchFacetSelectedValues
      : [searchFacetSelectedValues];
  }

  return searchFiltersFromUrl;
}
