import { TextBadge } from 'components/Badge/TextBadge';
import { SearchFilterAccordionItem } from 'modules/search/components/Filters/AccordionItem/SearchFilterAccordionItem';
import { SearchFilterDropdown } from 'modules/search/components/Filters/Dropdown/SearchFilterDropdown';
import { SearchFilterCheckboxFacetOptions } from 'modules/search/components/Filters/FacetOptions/SearchFilterCheckboxFacetOptions';
import { useSearchFacetSelectedValues } from 'modules/search/hooks/filters/useSearchFacetSelectedValues';
import { SearchFilterVariant } from 'modules/search/types/SearchFilterVariant';
import { EDUCATION_LEVEL_CHECKBOX_OPTIONS } from 'utils/constants/general/educationLevels';

type Props = {
  variant?: SearchFilterVariant;
};

export function EducationFilter({ variant = 'dropdown' }: Props) {
  // @ts-expect-error Fix Type
  const selectedFilterValues = useSearchFacetSelectedValues('education');

  const FilterVariant =
    variant === 'accordion' ? SearchFilterAccordionItem : SearchFilterDropdown;

  return (
    <FilterVariant
      facetType="education"
      icon="open-book"
      label={
        <>
          {getText('Education')}{' '}
          <TextBadge content={selectedFilterValues.length} />
        </>
      }
    >
      <SearchFilterCheckboxFacetOptions
        facetType="education"
        initialValue={selectedFilterValues}
        options={EDUCATION_LEVEL_CHECKBOX_OPTIONS}
      />
    </FilterVariant>
  );
}
