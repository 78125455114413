import { useCallback, useEffect, useState } from 'react';

type ToastDismissalData = {
  [toastId: string]: {
    dismissedAt: number;
  };
};

const emptyToastDismissalData: ToastDismissalData = {};
export const useSignInOrUpReminderPersistence = () => {
  const LS_KEY = 'idealist:sign-in-or-up-reminder';
  const getDismissedToasts = () => {
    const dismissedToastsJson = localStorage.getItem(LS_KEY);
    try {
      return dismissedToastsJson
        ? JSON.parse(dismissedToastsJson)
        : emptyToastDismissalData;
    } catch {
      localStorage.removeItem(LS_KEY);
      return emptyToastDismissalData;
    }
  };

  const [dismissedToasts, setDismissedToasts] = useState<ToastDismissalData>(
    () => getDismissedToasts(),
  );

  const clear = useCallback(() => {
    localStorage.removeItem(LS_KEY);
    setDismissedToasts(emptyToastDismissalData);
  }, []);

  const persistDismissedToastId = useCallback(
    (toastId: string) => {
      const updatedToasts = {
        ...dismissedToasts,
        [toastId]: {
          dismissedAt: Date.now(),
        },
      };
      setDismissedToasts(updatedToasts);
      localStorage.setItem(LS_KEY, JSON.stringify(updatedToasts));
    },
    [dismissedToasts],
  );

  // Export debug API for testing purposes
  useEffect(() => {
    Object.assign(window, {
      Idealist: {
        ...window.Idealist,
        debugSignInOrUpPersistence: {
          dismissedToasts,
          persistDismissedToastId,
          getDismissedToasts,
          clear,
        },
      },
    });
  }, [clear, dismissedToasts, persistDismissedToastId]);

  const hasDismissedToast = useCallback(
    (toastId: string) => toastId in dismissedToasts,
    [dismissedToasts],
  );

  const getDismissal = useCallback(
    (toastId: string) => dismissedToasts[toastId],
    [dismissedToasts],
  );

  return {
    hasDismissedToast,
    getDismissal,
    clear,
    persistDismissedToastId,
  };
};
