import { useMemo, useRef } from 'react';

import { useSearchFacetCounts } from 'modules/search/zustand-stores/searchStore';

type AlgoliaFacetCounts = Record<string, Record<string, number>> | null;

export function useSearchFacetLabelsWithCounts(
  filterType: string,
  facets: Array<{ label: string; name: string }>,
) {
  const facetCounts = useSearchFacetCounts();

  const prevFacetCountsRef = useRef<AlgoliaFacetCounts>(null);

  const facetsWithCounts = useMemo(() => {
    if (!facetCounts) {
      return facets;
    }

    if (prevFacetCountsRef.current === null) {
      prevFacetCountsRef.current = {};
    }

    const countsForType =
      facetCounts[filterType] || prevFacetCountsRef.current[filterType] || {};

    prevFacetCountsRef.current = {
      ...facetCounts,
      [filterType]:
        facetCounts[filterType] || prevFacetCountsRef.current[filterType],
    };

    return facets.map(({ label, name }) => ({
      label: `${label} (${countsForType[name] || 0})`,
      name,
    }));
  }, [facetCounts, prevFacetCountsRef, facets, filterType]);

  return facetsWithCounts;
}
