import styled from 'styled-components';

import { colors, cssBreakpoints, spacing } from 'theme/theme';

const SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH = '165px';

export const SearchFiltersHolder = styled.form`
  z-index: 1;
  background: ${colors.white};
  border: 1px solid ${colors.selectionGrey};
  padding: ${spacing[16]} ${spacing[24]};

  @media all and (max-width: ${cssBreakpoints.mdDown}) {
    display: none;
  }

  & > div > :nth-child(n + 5):not(:last-child) {
    display: none;
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH})) {
    & > div > :nth-child(5):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 2)) {
    & > div > :nth-child(6):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 3)) {
    & > div > :nth-child(7):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 4)) {
    & > div > :nth-child(8):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 5)) {
    & > div > :nth-child(9):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 6)) {
    & > div > :nth-child(10):not(:last-child) {
      display: initial;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 7)) {
    & > div > :nth-child(11):not(:last-child) {
      display: initial;
    }
  }

  /* Overflow menu visibility tracking */

  /* SimilarToFilter should never appear in overflow menu */
  & > div > :last-child menu [data-facet-type='synonymId'] {
    display: none;
  }

  /* First 3 always hidden */
  & > div > :last-child menu details:nth-of-type(-n + 3) {
    display: none;
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH})) {
    & > div > :last-child menu details:nth-of-type(4) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 2)) {
    & > div > :last-child menu details:nth-of-type(5) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} *
    3)) {
    & > div > :last-child menu details:nth-of-type(6) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} *
    4)) {
    & > div > :last-child menu details:nth-of-type(7) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} *
    5)) {
    & > div > :last-child menu details:nth-of-type(8) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} *
    6)) {
    & > div > :last-child menu details:nth-of-type(9) {
      display: none;
    }
  }

  @media all and (min-width: calc(${cssBreakpoints.mdUp} + ${SEARCH_FILTERS_DROPDOWN_CHIP_WIDTH} * 7)) {
    & > div > :last-child menu details:nth-of-type(10) {
      display: none;
    }
  }
`;
