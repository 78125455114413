/*
 * By using CSS variables we remove the need for JS to communicate between components
 * through transient properties, relying only on CSS.
 *
 * This effectively means that the component is immediately responsive to user input, which is important for a good UX,
 * which in turn impacts our lighthouse score.
 *
 */
export const detailsOneWhenClosedMinusOneWhenOpenVar =
  '--details-one-closed-minus-one-open';

export const detailsOneWhenClosedZeroWhenOpenVar =
  '--details-one-closed-zero-open';

export const detailsZeroWhenClosedOneWhenOpenVar =
  '--details-zero-closed-one-open';

export const detailsBrandBlueWhenOpenVar = '--details-brand-blue-when-open';
export const detailsCalloutGreyWhenOpenVar = '--details-callout-grey-when-open';
export const detailsSelectionGreyWhenOpenVar =
  '--details-selection-grey-when-open';
