// TODO(@paprikka): remove lodash dependency, use utils/functional/string/uuid
import uniqueId from 'lodash/uniqueId';
import { ReactNode } from 'react';
import { create } from 'zustand';

// Types for Toasts
export type ToastVariant =
  | 'warning'
  | 'default'
  | 'success'
  | 'info'
  | 'alternate';

// TODO(@paprikka):  this is too opinionated, we could just pass in a component here
export type ActionToastButtonAction = {
  label: string;
  onClick: () => void;
  type: 'button';
};

export type ActionToastFeedbackAction = {
  id: string;
  onFeedback: (result: 'positive' | 'negative') => void;
  type: 'feedback';
};

export type ActionToastAction =
  | ActionToastButtonAction
  | ActionToastFeedbackAction;

export type ToastOptions = {
  id?: string;
  variant?: ToastVariant;
  headline: string;
  body?: string;
  icon?: ReactNode;
  // TODO(@paprikka): rename to include time unit, e.g. durationMs
  duration?: number; // in milliseconds
  dismissible?: boolean;
  layout?: 'vertical' | 'compact';
  actions?: ActionToastAction[];
  onDismiss?: () => void;
};

// Zustand Store
type ToastState = {
  toasts: ToastOptions[];
  showToast: (options: ToastOptions) => void;
  dismissToast: (id: string) => void;
  clearToasts: () => void;
  isDisplayingToast: (id: string) => boolean;
};

export type ToastDismissedEventDetail = { id: string };
export const useToastStore = create<ToastState>((set, get) => ({
  toasts: [],
  showToast: (options) => {
    const id = options.id || uniqueId();
    set((state) => ({
      toasts: [...state.toasts, { ...options, id }],
    }));
    if (options.duration) {
      setTimeout(() => {
        window.dispatchEvent(
          new CustomEvent<ToastDismissedEventDetail>(
            'idealist:toast-dismissed',
            {
              detail: { id },
            },
          ),
        );
      }, options.duration);
    }
  },
  dismissToast: (id) =>
    set((state) => {
      const toast = state.toasts.find((t) => t.id === id);
      if (toast?.onDismiss) toast.onDismiss();

      return {
        toasts: state.toasts.filter((t) => t.id !== id),
      };
    }),
  clearToasts: () => set(() => ({ toasts: [] })),
  isDisplayingToast: (id) => get().toasts.some((toast) => toast.id === id),
}));

// Custom Hook
export const useActionToast = () => {
  const { showToast, dismissToast, clearToasts } = useToastStore();
  return { showToast, dismissToast, clearToasts };
};
