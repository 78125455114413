import { useEffect } from 'react';

import { ApiUserId } from 'api/types/ApiTypedId';
import {
  getUserEnvironment,
  useUser,
} from 'zustand-stores/userEnvironmentStore';
import { createSsrApiDataStore } from 'zustand-stores/utils/createSsrApiDataStore';

type Args<
  TData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCreateArgs extends any[],
  TUpdatePayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TDeleteArgs extends any[],
> = {
  fetch: () => Promise<TData[]>;
  getSsrApiData: () => TData[] | undefined;
  create: (...args: TCreateArgs) => Promise<TData>;
  update?: (id: string, payload: TUpdatePayload) => Promise<TData>;
  delete?: (...args: TDeleteArgs) => Promise<string>;
};

/**
 * This is an abstraction for:
 * - saved-listings
 * - saved-searches
 * - listing-applications
 *
 * All three rely on SSR Api Data and the logged-in user.
 *
 * It is often counter-productive, but given all three stores mentioned
 * above follow the exact same pattern, it seems to be a reasonable approach.
 */
export function userDashboardCreateThingsStore<
  TData extends { id: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCreateArgs extends any[],
  TUpdatePayload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TDeleteArgs extends any[],
>({
  fetch,
  create,
  update,
  delete: unsave,
  getSsrApiData,
}: Args<TData, TCreateArgs, TUpdatePayload, TDeleteArgs>) {
  let fetched: { userId: ApiUserId | undefined };

  const { store: thingsStore, hook: useThingsStore } = createSsrApiDataStore<{
    things: TData[];
  }>({
    getSsrState: () => {
      const things = getSsrApiData();
      if (things) {
        fetched = { userId: getUserEnvironment().user?.id };
        return { things };
      }
    },
    fallbackState: { things: [] },
  });

  const fetchOnce = async () => {
    const userId = getUserEnvironment().user?.id;

    if (fetched && fetched.userId === userId) return;
    fetched = { userId };

    const things = userId ? await fetch() : [];
    thingsStore.setState({ things });
  };

  const getThings = () => thingsStore.getState().things;

  const useFetchOnce = () => {
    const { user } = useUser();

    useEffect(() => {
      fetchOnce();
    }, [user?.id, user?.emailVerified]); // Keep the user id & emailVerified dependencies
  };

  const createThing = async (...args: TCreateArgs) => {
    await fetchOnce();
    const newThing = await create(...args);

    thingsStore.setState({ things: [...getThings(), newThing] });
    return newThing;
  };

  const updateThing = async (id: string, payload: TUpdatePayload) => {
    if (!update) return;

    await fetchOnce();
    const updatedThing = await update(id, payload);

    thingsStore.setState({
      things: getThings().map((thing) =>
        thing.id === id ? updatedThing : thing,
      ),
    });
  };

  const deleteThing = async (...args: TDeleteArgs) => {
    if (!unsave) return;

    await fetchOnce();

    const id = await unsave(...args);

    thingsStore.setState({
      things: getThings().filter((thing) => thing.id !== id),
    });
  };

  const useThings = () => {
    useFetchOnce();
    return useThingsStore((state) => state.things);
  };

  const useIsThingsSaved = (id: string | null | undefined) => {
    useFetchOnce();

    return useThingsStore((state) =>
      state.things.find((thing) => thing.id === id),
    );
  };

  return {
    useThings,
    createThing,
    updateThing,
    deleteThing,
    useIsThingsSaved,
  };
}
