import { useCallback, useRef } from 'react';

import { Button } from 'components/Button/Button';
import { closeDropdownFromElementWithin } from 'components/Dropdown/closeDropdownFromElementWithin';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { H4 } from 'components/Text/H4';
import { UncontrolledSearchQueryInputContainer } from 'containers/UncontrolledSearchQueryInputContainer';
import { UncontrolledListingTypeSelect } from 'modules/listing/components/ListingTypeSelect/UncontrolledListingTypeSelect';
import { SearchButton } from 'modules/search/components/SearchButton';
import { searchTypes } from 'modules/search/constants/searchTypes';
import { usePopularSearches } from 'modules/search/hooks/usePopularSearches';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import { colors } from 'theme/theme';

import {
  PageHeaderMobileSearchMenuBody,
  PageHeaderMobileSearchMenuHeader,
  PageHeaderMobileSearchMenuHolder,
  PageHeaderSearchButtonWrapper,
  PageHeaderSearchInputsMobileForm,
} from './PageHeaderMobileSearchMenu.styled';
import { PopularSearches } from './PopularSearches';

type Props = {
  defaultSearchType: SearchType;
  initialQuery: string;
  searchLocation: SearchLocation | undefined;
  onChangeSearchLocation: (
    newSearchLocation: SearchLocation | undefined,
  ) => boolean;
  onSearch: (data: {
    listingType: SearchType;
    query: string;
    triggeredByRemoteLocationChange: boolean;
  }) => void;
  searchOnClear: boolean;
};

export function PageHeaderMobileSearchMenu({
  defaultSearchType,
  initialQuery,
  searchLocation,
  onChangeSearchLocation,
  onSearch,
  searchOnClear,
}: Props) {
  const {
    popularSearches,
    listingTypeForPopularSearches,
    setListingTypeForPopularSearches,
  } = usePopularSearches(defaultSearchType);
  const formRef = useRef<HTMLFormElement>(null);
  const submit = useCallback(
    (options: { triggeredByRemoteLocationChange: boolean }) => {
      const form = formRef.current;
      if (!form) return;

      const formData = new FormData(form);
      onSearch({
        query: formData.get('q') as string,
        listingType: formData.get('listing-type') as SearchType,
        triggeredByRemoteLocationChange:
          options.triggeredByRemoteLocationChange,
      });

      closeDropdownFromElementWithin(form);
    },
    [onSearch],
  );

  return (
    <PageHeaderMobileSearchMenuHolder data-qa-id="mobile-search-menu">
      <PageHeaderMobileSearchMenuHeader>
        <H4 mb={0} color={colors.brandBlue}>
          {getText('Search')}
        </H4>

        <Button
          type="button"
          data-qa-id="close-mobile-search"
          onClick={(event) => {
            closeDropdownFromElementWithin(event.target as HTMLElement);
          }}
          aria-label={getText('Close search')}
          variant="tertiary"
          noPadding
          icon={{ type: 'normalized', name: 'close' }}
        />
      </PageHeaderMobileSearchMenuHeader>

      <PageHeaderMobileSearchMenuBody>
        <PageHeaderSearchInputsMobileForm
          ref={formRef}
          onSubmit={(event) => {
            event.preventDefault();
            submit({ triggeredByRemoteLocationChange: false });
          }}
          data-qa-id="search-inputs"
        >
          <UncontrolledListingTypeSelect
            name="listing-type"
            defaultValue={defaultSearchType}
            searchTypes={searchTypes}
            variant="grey"
            onChange={(newListingType) =>
              setListingTypeForPopularSearches(newListingType)
            }
          />

          <UncontrolledSearchQueryInputContainer
            initialQuery={initialQuery}
            placeholder={getText('Search by keyword, skill, or interest')}
            onClearQuery={
              searchOnClear
                ? () => submit({ triggeredByRemoteLocationChange: false })
                : undefined
            }
            presentation="contrast"
            id="mobile-search-query"
            qaId="mobile-search-input"
            selectedType={defaultSearchType}
          />

          <LocationInput
            affixVariant="transparent"
            styleVariant="contrast"
            hasRemoteOptions
            showLocationIcon
            showClearButton
            placeholder={getText('Everywhere')}
            locationText={searchLocation?.text}
            onChange={(newLocation) => {
              // Avoid submitting twice - the LocationInput has a bug that triggers the onChange twice
              if (!onChangeSearchLocation(newLocation)) return;

              // Trigger search when search location changes
              submit({
                triggeredByRemoteLocationChange: Boolean(newLocation?.isRemote),
              });
            }}
            id="page-header-mobile-search-location"
          />
          <PageHeaderSearchButtonWrapper>
            <SearchButton />
          </PageHeaderSearchButtonWrapper>
        </PageHeaderSearchInputsMobileForm>
      </PageHeaderMobileSearchMenuBody>

      <PopularSearches
        selectedType={listingTypeForPopularSearches}
        popularSearches={popularSearches}
        onSearch={(event, query) => {
          closeDropdownFromElementWithin(event.target as HTMLElement);
          onSearch({
            listingType: listingTypeForPopularSearches,
            query,
            triggeredByRemoteLocationChange: false,
          });
        }}
      />
    </PageHeaderMobileSearchMenuHolder>
  );
}
